import { useQuery, useMutation, useResult } from '@vue/apollo-composable'
import { USER, UPDATE_USER } from '@/core/graphql/user-gql'
import { User } from '@/core/types/entities/user/user'
import { UpdateUserDto } from '@/core/types/entities/user/dto/update-user.dto'

export const useCurrentUser = () => {
  const { result, ...rest } = useQuery<User>(USER)
  const currentUser = useResult(result, null)

  const {
    mutate: update,
    loading: updateLoading,
    onDone: onDoneUpdate,
  } = useMutation<{ updateUser: { user: User } }, UpdateUserDto>(UPDATE_USER)

  const onDoneUpdateStub = (fn: ((user?: User | null) => void)) => {
    return onDoneUpdate(result => {
      if (result) {
        const user = result.data?.updateUser.user
        fn(user)
      }
    })
  }

  return {
    currentUser,
    ...rest,
    update,
    updateLoading,
    onDoneUpdate: onDoneUpdateStub,
  }
}
