import { computed } from '@vue/composition-api'
import { ModalType } from '@/core/types/modal'
import { useStore } from '@/composables'

export const useModal = () => {
  const store = useStore()

  const update = (modalPartial: Partial<ModalType>) => {
    store.dispatch('Update modal', modalPartial)
  }

  const reset = () => store.dispatch('Reset modal')

  const hide = () => update({ isActive: undefined })

  const show = (partialModal?: Partial<Omit<ModalType, 'isActive'>>) => {
    update({ ...partialModal, isActive: true })
  }

  return {
    modal: computed<ModalType>(() => store.getters.modal),
    reset,
    hide,
    show,
  }
}
