import { ref, onBeforeUnmount } from '@vue/composition-api'

import { KEEP_ALIVE_INTERVAL } from '@/config'
import { keepAlive } from '@/core/services'

export const useKeepAlive = () => {
  const intervalId = ref<ReturnType<typeof setInterval>>()

  const start = () => {
    intervalId.value = setInterval(keepAlive, KEEP_ALIVE_INTERVAL)
  }

  const stop = () => {
    if (intervalId?.value) clearInterval(intervalId.value)
  }

  onBeforeUnmount(stop)

  return {
    start,
    stop,
  }
}
