import _ from 'lodash'
import moment from 'moment'
import { Ref, unref } from '@vue/composition-api'

import {
  Entity,
  DomainSpecificEntity,
} from '@/core/types/entities/entity'
import { analytics } from '@/utils/analytics/base'
import { Project } from '@/core/types/entities/project/project/project'
import { Dataset } from '@/core/types/entities/dataset/dataset'

export const extractProjectProps = (project: Project) => ({
  name: project.name,
  n_of_datasets: project.mountedDatasets?.length,
  compute_type: project.computeType,
  hardware: project.hardware,
  state: project.state,
  has_init_script: project.initScript?.length > 0,
  n_of_envvars: project.envVars?.length,
  n_of_dependencies: project.dependencies?.length,
})

export const extractDatasetProps = (dataset: Dataset) => ({
  name: dataset.name,
  storage: dataset.storageType,
})

export type AnalyticsProps = any

export type DefaultAnalyticsProps = {
  source: string,
  trigger: string
}

export const getEventName = (subject: string, action: string) => (
  `${subject || 'ERROR'} ${action}`)

const extractEntityBaseProps = (entity: Entity) => ({
  typename: entity.__typename,
  id: entity.id,
  created: entity.createdAt,
  updated: entity.updatedAt,
})

export const extractSpecificProps = (entity: DomainSpecificEntity) => {
  switch (entity.__typename) {
    case 'Project':
      return extractProjectProps(entity)
    case 'Dataset':
      return extractDatasetProps(entity)
  }
}

export const extractEntityProps = (
  entity: DomainSpecificEntity) => ({
  ...extractEntityBaseProps(entity),
  // @ts-ignore
  ...extractDaysSince(entity.createdAt, 'created'),
  // @ts-ignore
  ...extractDaysSince(entity.updatedAt, 'updated'),
  ...extractSpecificProps(entity),
})

type UnitOfTime = Parameters<ReturnType<typeof moment>['diff']>[1]
export const extractPropsFromDate = (
  date: string,
  suffix: string,
  period: UnitOfTime = 'days') => ({
  [`${period}_since_${suffix}`]: moment().diff(date, period),
})

export const extractDaysSince = (
  _.partialRight(extractPropsFromDate, 'days'))

export const trackEntityEvent = (action: string) => (
  entity: DomainSpecificEntity | Ref<DomainSpecificEntity>,
  props?: AnalyticsProps) => {
  const e = unref(entity)
  const entityProps = extractEntityProps(e)
  const eventName = getEventName(e.__typename, action)
  analytics().track(eventName, { ...entityProps, ...props })
}

export const trackMountOrUnmount = (action: string) => (
  entity: DomainSpecificEntity | Ref<DomainSpecificEntity>,
  on?: DomainSpecificEntity | Ref<DomainSpecificEntity>,
  props?: any,
) => {
  const subject = unref(entity)
  const subjectProps = subject ? extractEntityProps(subject) : {}

  const other = unref(on)
  const otherProps = (
    other ? _.mapKeys(extractEntityProps(other), (_, k) => `on_${k}`) : {})

  const eventName = getEventName(subject.__typename, action)
  analytics().track(eventName, { ...subjectProps, ...otherProps, ...props })
}
