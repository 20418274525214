import moment from 'moment'

export const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const fromNow = (date: string, format?: string) => {
  return moment(date, format).fromNow()
}

export const momentFormat =
  (date: string,
    format = 'MMMM Do YYYY, h:mm:ss a',
  ) => {
    return moment(date).format(format)
  }
