const HTTPS_PROTOCOL = 'https'
const WSS_PROTOCOL = 'wss'

const PROTOCOLS = {
  HTTPS_PROTOCOL,
  WSS_PROTOCOL,
}

const makeURL = (protocol: string, hostname: string) => {
  return `${protocol}://${hostname}`
}

export {
  PROTOCOLS,
  makeURL,
}
