/**
 * Inspired by apollo-composable which doesn't export it
 * https://github.com/vuejs/vue-apollo/blob/35ea099209a36f4825988f9c0f4343441d9ff3f7/packages/vue-apollo-composable/src/util/useEventHook.ts
 */

export const useEventHook = <Param = any>() => {
  const fns: ((param?: Param) => void)[] = []

  const on = (fn: (param?: Param) => void) => {
    fns.push(fn)
    return {
      off: () => off(fn),
    }
  }

  const off = (fn: (param?: Param) => void) => {
    const index = fns.indexOf(fn)
    if (index !== -1) {
      fns.splice(index, 1)
    }
  }

  const trigger = (param?: Param) => {
    for (const fn of fns) {
      fn(param)
    }
  }

  return {
    off,
    on,
    trigger,
  }
}
