import { GetterTree, MutationTree, ActionTree, Module } from 'vuex'
import { RootState } from '.'

import { Alert } from '@/core/types/alert'

const RESET_ALERT = 'Reset alert'
const UPDATE_ALERT = 'Update alert'

type State = {
  alert: Alert
}

const defaultState: State = {
  alert: {
    isVisible: false,
  },
}

const getters = <GetterTree<State, RootState>> {
  alert: state => {
    return state.alert
  },
}

const mutations = <MutationTree<State>> {
  [RESET_ALERT] (state) {
    state.alert = { isVisible: false }
  },
  [UPDATE_ALERT] (state, alertPartial) {
    state.alert = { ...state.alert, ...alertPartial }
  },
}

const actions = <ActionTree<State, RootState>> {
  resetAlert ({ commit }) {
    commit(RESET_ALERT)
  },
  updateAlert ({ commit }, alertPartial: Partial<Alert>) {
    commit(UPDATE_ALERT, alertPartial)
  },
}

export const alertModule: Module<State, RootState> = {
  namespaced: false,
  state: defaultState,
  getters: getters,
  mutations: mutations,
  actions: actions,
}
