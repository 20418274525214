import {
  CustomErrorType,
  ErrorType,
} from '@/core/types/entities/error/custom-error'

export const CustomError = (props: { message: string,
  code?: string, type: ErrorType }): CustomErrorType => {
  let message = props.message
  if (props.type === ErrorType.BACKEND_GRAPHQL) {
    try {
      const parsedMessage = JSON.parse(props.message)
      if (parsedMessage && parsedMessage.error && parsedMessage.error.message) {
        message = parsedMessage.error.message
      }
    } catch (e) {
      message = props.message
    }
  }
  return {
    message,
    code: props.code,
  }
}
