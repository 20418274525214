
import {
  defineComponent,
  h,
  onBeforeMount,
} from '@vue/composition-api'

import { processGithubOauthResponse } from '@/core/utils/github-connect'
import { useRouter, useRoute } from '@/composables'
import { useAlert } from '@/composables/use-alert'
import { Alert } from '@/core/types/alert'
import { trackConnectionAdd } from '@/utils/analytics/connection'

export default defineComponent({
  setup () {
    const router = useRouter()
    const { query } = useRoute()
    const { showAlert } = useAlert()

    onBeforeMount(async () => {
      let alert: Partial<Alert> | undefined
      try {
        const res = await processGithubOauthResponse(query)
        if (res.status === 200) {
          trackConnectionAdd('Github')
          alert = {
            type: 'success',
            message: 'Successfully linked your github account!',
          }
        } else {
          throw new Error('Authentication failed...')
        }
      } catch (e) {
        const error = e as Error
        alert = { type: 'error', message: error.message }
      } finally {
        await router.push({ name: 'settings' })
        if (typeof alert !== 'undefined') showAlert(alert)
      }
    })
  },
  render () {
    return h('', '')
  },
})
