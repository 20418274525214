import { LocalStorageKey } from '@/core/types/local-storage'

const getLocalStorage =
  <T extends any | null>(key: LocalStorageKey): T | null => {
    const stringObject = localStorage.getItem(key)
    if (!stringObject) return null
    return JSON.parse(stringObject)
  }

const getOrThrowLocalStorage =
  <T extends any | null>(key: LocalStorageKey): T => {
    const stringObject = localStorage.getItem(key)
    if (!stringObject) throw new Error('Please log in')
    return JSON.parse(stringObject)
  }

const setLocalStorage = <T>(key: LocalStorageKey, object: T): void => {
  localStorage.setItem(key, JSON.stringify(object))
}

const removeLocalStorage = (key: LocalStorageKey): void => {
  localStorage.removeItem(key)
}

export {
  getLocalStorage,
  getOrThrowLocalStorage,
  setLocalStorage,
  removeLocalStorage,
}
