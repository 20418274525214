







































































































import {
  computed,
  defineComponent, onMounted, ref,
  watch,
} from '@vue/composition-api'

import { Project as ProjectType }
  from '@/core/types/entities/project/project/project'
import { useRouter } from '@/composables'
import { useAppBar } from '@/composables/use-appbar'
import { useValidateDelete } from '@/composables/use-modals/use-validate-delete'
import { trackProjectDelete } from '@/utils/analytics/project'
import { useSnackbar } from '@/composables/use-snackbar'
import { isProd } from '@/utils'
import { useProjectFileTree } from '@/composables/use-project-file-tree'
import { useProject } from '@/composables/entities/projects/project/use-project'
import { useUpdateProject }
  from '@/composables/entities/projects/project/use-update-project'

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    'SkeletonLoader': () => import('@/components/molecules/SkeletonLoader.vue'),
    'DebugInfos': () => import('@/components/organisms/DebugInfos.vue'),
    'DatasetManager': () => import('@/components/organisms/DatasetManager.vue'),
    'ProjectMounter': () => import('@/components/organisms/ProjectMounter.vue'),
    'UpdateProjectForm':
      () => import('@/components/organisms/UpdateProjectForm.vue'),
    'ProjectServiceManager': () => import(
      '@/components/organisms/project-service-manager/ProjectServiceManager.vue'
    ),
    'ProjectFile': () => import('@/components/organisms/ProjectFile.vue'),
    'ProjectServicesCarousel':
      () => import('@/components/organisms/ProjectServicesCarousel.vue'),
  },
  setup (props, { root }) {
    const {
      activeFiles,
      openedFiles,
      findFileItem,
      findReadmeFileItem,
    } = useProjectFileTree()

    const {
      project,
      loading,
      deleteProject,
      onDoneDelete,
      onResult,
    } = useProject(props.id)

    const filePath = computed(
      () => root.$route.query.filePath as string | undefined,
    )

    onResult((project) => {
      if (filePath.value) {
        findFileItem(project.filetree!, filePath.value)
      } else {
        findReadmeFileItem(project.filetree!)
      }
    })

    const updateFilePathInQueryParam = (newFilePath: string) => {
      router.replace({
        name: 'Project.show',
        params: { subView: subView.value },
        query: { filePath: newFilePath },
      })
    }

    watch(() => activeFiles.value, (newActiveFiles) => {
      if (newActiveFiles.length > 0) {
        updateFilePathInQueryParam(newActiveFiles[0].path)
      }
    }, { immediate: true })

    const { update } = useUpdateProject()
    const { showSuccess } = useSnackbar()
    const { updateAppBar } = useAppBar()
    updateAppBar({
      title: {
        editAction: async (value: ProjectType['name']) => {
          await update({ id: props.id, name: value })
          showSuccess('Update successful')
        },
      },
    })
    watch(() => project.value?.name, (name) => {
      updateAppBar({ title: { label: name } })
    }, { immediate: true })

    const router = useRouter()
    onDoneDelete(project => {
      showSuccess('Project removed')
      trackProjectDelete(project)
      router.push({ name: 'Project.index' })
    })

    const { validateDelete } = useValidateDelete()
    const handleDelete = () => validateDelete(
      project.value?.name, deleteProject,
    )

    const PROJECT_CONFIG_TAB_NAME = 'Project config'
    const PROJECT_SERVICES_TAB_NAME = 'Project services'
    const DATASETS_TAB_NAME = 'Datasets'
    const DEBUG_INFO_TAB_NAME = 'Debug info'
    const PROJECT_CONFIG_TAB_SUBVIEW = 'config'
    const PROJECT_SERVICES_TAB_SUBVIEW = 'services'
    const DATASETS_TAB_SUBVIEW = 'datasets'
    const DEBUG_INFO_TAB_SUBVIEW = 'debug'
    const selectedTab = ref()
    const TABS = [
      {
        name: PROJECT_CONFIG_TAB_NAME,
        to: PROJECT_CONFIG_TAB_SUBVIEW,
      },
      {
        name: PROJECT_SERVICES_TAB_NAME,
        to: PROJECT_SERVICES_TAB_SUBVIEW,
      },
      {
        name: DATASETS_TAB_NAME,
        to: DATASETS_TAB_SUBVIEW,
      },
      {
        name: DEBUG_INFO_TAB_NAME,
        to: DEBUG_INFO_TAB_SUBVIEW,
      },
    ]

    const isDebugTab = (tabName: string) => (tabName === DEBUG_INFO_TAB_NAME)
    const updateSubView = (subView: string) => router.replace({
      name: 'Project.show',
      params: { subView },
      query: { filePath: filePath.value },
    })
    const subView = computed(() => root.$route.params.subView)
    const selectSubView = () => {
      subView.value
        ? selectedTab.value = TABS.findIndex((tab) => tab.to === subView.value)
        : updateSubView(PROJECT_CONFIG_TAB_SUBVIEW)
    }
    onMounted(() => {
      selectSubView()
    })

    return {
      activeFiles,
      openedFiles,
      loading,
      project,
      handleDelete,
      isProd,
      TABS,
      isDebugTab,
      updateSubView,
      selectedTab,
    }
  },
})
