import { FileTreeItem } from '@/core/types/entities/project/project/project'
import _ from 'lodash'
import { ref } from '@vue/composition-api'

export const useProjectFileTree = () => {
  const README_FILE_NAME = 'README.md'
  const FILE_PATH_SEPARATOR = '/'
  const FILE_TYPE = 'file'

  const activeFiles = ref<FileTreeItem[]>([])

  const openedFiles = ref<FileTreeItem[]>([])

  const addToActiveFiles = (fileItem: FileTreeItem) => {
    activeFiles.value.push(fileItem)
  }

  const addToOpenedFiles = (fileItem: FileTreeItem) => {
    openedFiles.value.push(fileItem)
  }

  const findReadMe =
    (fileTree: FileTreeItem[]): FileTreeItem | undefined => _.find(
      fileTree, (item: FileTreeItem) => (
        item.type === FILE_TYPE && item.name === README_FILE_NAME
      ))

  const findReadmeFileItem = (filetree: FileTreeItem[]) => {
    const fileItem = findReadMe(filetree)
    if (fileItem) {
      addToActiveFiles(fileItem)
      addToOpenedFiles(fileItem)
    }
  }

  const openFolderInFileTree =
    (filetree: FileTreeItem[], folderPaths: string[]) => {
      for (const folderPath of folderPaths) {
        const folderItem = findFileTreeItemByPath(filetree, folderPath)
        if (folderItem) {
          addToOpenedFiles(folderItem)
        }
      }
    }

  const getFolderPathsFromFilePath = (filePath: string) =>
    filePath
      .split(FILE_PATH_SEPARATOR)
      .slice(0, -1) // Remove the filetree file name

  const findFileItem = (filetree: FileTreeItem[], filePath: string) => {
    const fileItem = findFileTreeItemByPath(filetree, filePath)
    if (fileItem) {
      addToActiveFiles(fileItem)
      const folderPaths = getFolderPathsFromFilePath(fileItem.path)
      openFolderInFileTree(filetree, folderPaths)
    }
  }

  const searchFileTreeItemByPath =
    (items: FileTreeItem[], filePath: string) => {
      const childrenItems: FileTreeItem[][] = []
      let result: FileTreeItem | undefined
      for (const item of items) {
        if (item.path.includes(filePath)) {
          result = item
          break
        } else if (item.children) {
          childrenItems.push(item.children)
        }
      }
      return { result, childrenItems }
    }

  const findFileTreeItemByRecursion = (
    items: FileTreeItem[][],
    filePath: string,
  ): FileTreeItem | undefined => {
    const childrenItems: FileTreeItem[][] = []
    for (const item of items) {
      const {
        result,
        childrenItems: newChildrenItem,
      } = searchFileTreeItemByPath(item, filePath)
      if (result) return result
      childrenItems.push(...newChildrenItem)
    }
    if (childrenItems.length) {
      return findFileTreeItemByRecursion(childrenItems, filePath)
    }
  }

  const findFileTreeItemByPath = (items: FileTreeItem[], filePath: string) => {
    return findFileTreeItemByRecursion([items], filePath)
  }

  return {
    findReadmeFileItem,
    findFileItem,
    activeFiles,
    openedFiles,
  }
}
