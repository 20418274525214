








import { defineComponent } from '@vue/composition-api'
import { useAppBar } from '@/composables'

export default defineComponent({
  components: {
    'AppBar': () => import('@/components/organisms/AppBar.vue'),
    'MainContent': () => import('@/components/organisms/MainContent.vue'),
    'Snackbar': () => import('@/components/snackbar/Snackbar.vue'),
  },
  setup () {
    const { resetAppBar } = useAppBar()
    resetAppBar()
  },
})
