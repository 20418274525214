import gql from 'graphql-tag'

export const datasetFields = gql`
  fragment datasetFields on Dataset {
    id
    createdAt
    updatedAt
    name
    isPublic
    storageType
    connectionParams
  }
`

export const serviceFields = gql`
  fragment serviceFields on Service {
    id
    name
    description
    required
    logoUrl
    darkModeLogoUrl
  }
`

export const serviceAssociationFields = gql`
  fragment serviceAssociationFields on ServiceAssociation {
    authRequired
    urlPath
    customCmdArgs
    redirectionUrl
    runDir
    service {
     ...serviceFields
    }
  }
  ${serviceFields}
`

export const editableProjectFields = gql`
    fragment editableProjectFields on Project {
        id
        name
        initScript
        dependencies
        envVars
        hardware
    }
`

export const nonEditableProjectFields = gql`
    fragment nonEditableProjectFields on Project {
        createdAt
        updatedAt
        canonicalName
        state
        filetree
        hasSnapshot
        secretToken
        hostname
        computeType
    }
`

export const projectFields = gql`
    fragment projectFields on Project {
        ...editableProjectFields
        ...nonEditableProjectFields
    }
    ${editableProjectFields}
    ${nonEditableProjectFields}
`

export const projectFieldsNested = gql`
  fragment projectFieldsNested on Project {
    ...projectFields
    mountedDatasets {
      ...datasetFields
    }
    owner {
      id
    }
    attachedServices {
      ...serviceAssociationFields
    }
  }
  ${projectFields}
  ${datasetFields}
  ${serviceAssociationFields}
`
