import VueProxy from 'vue/types'
import { computed } from '@vue/composition-api'

import { store } from '@/store'

export const useLayout = () => {
  const layout = computed(() => store.getters.layout as VueProxy)
  return {
    layout,
  }
}
