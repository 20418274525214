import { ref } from '@vue/composition-api'
import { Cookies, COOKIE_TOKEN } from '@/utils/cookies'
import { apolloClient } from '@/core/services/http-common'
import { useStore } from '@/composables'
import { removeLocalStorage } from '@/core/services/local-storage'
import { trackUserSignOut } from '@/utils/analytics/user'
import { router } from '@/router'

export const useLogoutUser = () => {
  const store = useStore()
  const loading = ref(false)

  const logout = async () => {
    loading.value = true
    Cookies.remove(COOKIE_TOKEN)
    apolloClient.clearStore()
    await store.dispatch('logoutUser')
    trackUserSignOut()
    removeLocalStorage('user')
    loading.value = false
    router.push({ name: 'login' })
  }

  return {
    logout,
    loading,
  }
}
