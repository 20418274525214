import gql from 'graphql-tag'

const userFields = gql`
  fragment userFields on User {
    id
    email
    username
    firstName
    lastName
    hasEnabledGithub
    sshPublicKey
    plan
    cloudCredit
 }
`

export const USER = gql`
  query getUser ($email: String) {
    user (email: $email) {
      ...userFields
    }
  }
  ${userFields}
`

export const UPDATE_USER = gql`
  mutation updateUser ($id: String,
                       $firstName: String,
                       $lastName: String,
                       $resetGithubToken: Boolean) {
    updateUser (id: $id,
                firstName: $firstName,
                lastName: $lastName,
                resetGithubToken: $resetGithubToken) {
      user {
        id,
        firstName,
        lastName,
        hasEnabledGithub
      }
    }
  }
`

const CREATE_OR_UPDATE_SSH_KEY = gql`
  mutation createOrUpdateUserSshKey ($userId: String) {
    createOrUpdateUserSshKey (userId: $userId) {
      user {
        id
        sshPublicKey
      }
    }
  }
`

const DELETE_USER_SSH_KEY = gql`
  mutation deleteUserSshKey ($userId: String) {
    deleteUserSshKey (userId: $userId) {
      user {
        id
        sshPublicKey
      }
    }
  }
`

export const SSH_KEY = {
  CREATE: CREATE_OR_UPDATE_SSH_KEY,
  UPDATE: CREATE_OR_UPDATE_SSH_KEY,
  DELETE: DELETE_USER_SSH_KEY,
}
