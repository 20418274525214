export const CREATE_ACTION = 'Created'
export const UPDATE_ACTION = 'Updated'
export const DELETE_ACTION = 'Deleted'
export const MOUNT_ACTION = 'Mounted'
export const UNMOUNT_ACTION = 'Unmounted'
export const ADD_ACTION = 'Added'
export const REMOVE_ACTION = 'Removed'
export const BUY_ACTION = 'Purchased'
export const CANCEL_ACTION = 'Cancelled'
export const DOWNLOAD_ACTION = 'Downloaded'
