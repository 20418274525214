import _ from 'lodash'
import {
  FileTreeItem,
  FileTreeItemWithoutPath,
  ProjectRaw,
  Project as ProjectType,
  ProjectState,
} from '@/core/types/entities/project/project/project'
import { EntityFactory } from '@/core/types/entities/entity'

const PROJECT_PROTOCOL = 'https'

export const getProjectOrigin = (project: ProjectType): string => {
  return `${PROJECT_PROTOCOL}://${project.hostname}`
}

// TODO: refactor this
const pathFormat: (
  (items: FileTreeItemWithoutPath[], path?: string) => FileTreeItem[]
) = (items, path) => {
  const recurse = (item: FileTreeItemWithoutPath, path?: string) => {
    const { children, ...rest } = item
    const newPath = path ? path + '/' + item.name : item.name
    return {
      children: children ? pathFormat(children, newPath) : children,
      ...rest,
      path: newPath,
    }
  }
  return _.map(items, (item) => recurse(item, path))
}

export const formatTree = (filetree?: string) => {
  if (!filetree) return []
  // FIXME: Don't understand why the need to parse twice??
  return pathFormat(JSON.parse(JSON.parse(filetree)))
}

export const Project: EntityFactory<ProjectRaw, ProjectType> = (rawProject) => {
  if (!rawProject) return null
  return {
    ...rawProject,
    filetree: formatTree(rawProject.filetree),
    dependencies: JSON.parse(rawProject.dependencies),
    envVars: JSON.parse(rawProject.envVars),
    sshUrl: `ssh.${rawProject.hostname}`,
    // TODO create Mapper
    attachedServices: rawProject.attachedServices.map(projectService => ({
      ...projectService,
      customCmdArgs: JSON.parse(projectService.customCmdArgs),
    })),
  }
}

export const isProjectMounting = (state?: ProjectState) => (
  state === ProjectState.MOUNTING
)

export const isProjectMounted = (state?: ProjectState) => (
  state === ProjectState.MOUNTED
)

export const isProjectUnmounted = (state?: ProjectState) => (
  state === ProjectState.UNMOUNTED
)

export const isProjectUnmounting = (state?: ProjectState) => (
  state === ProjectState.UNMOUNTING
)
