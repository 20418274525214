import ValidateDelete from '@/components/organisms/ValidateDelete.vue'

import { useModal } from '@/composables/use-modals'

export const useValidateDelete = () => {
  const { show, reset } = useModal()

  const validateDelete = (name?: string, action?: any) => {
    const component = {
      component: ValidateDelete,
      props: {
        name,
        action: () => {
          if (action) action()
          reset()
        },
      },
    }
    show({ component })
  }

  return {
    validateDelete,
  }
}
