






































import { computed, defineComponent } from '@vue/composition-api'

import { Invoice } from '@/core/types/entities/billing/invoice'
import { useAppBar } from '@/composables'
import { useInvoices } from '@/composables/entities/billing/use-invoices'
import { DEFAULT_MAX_ITEMS_PER_PAGE, headerLayouts } from '@/core/utils/table'
import { trackInvoiceDownload } from '@/utils/analytics/invoice'

export default defineComponent({
  components: {
    'DataTable': () => import('@/components/molecules/DataTable.vue'),
  },
  setup () {
    const { setAppBar } = useAppBar()
    setAppBar({ title: { label: 'Payment history' } })
    const headers = headerLayouts.Invoice

    const { invoices, loading } = useInvoices()

    const downloadInvoice = (invoice: Invoice) => {
      window.open(invoice.invoicePdf, '_self')
      trackInvoiceDownload(invoice)
    }

    const isPaymentTableFooterHidden = computed(() =>
      invoices.value.length <= DEFAULT_MAX_ITEMS_PER_PAGE,
    )

    return {
      invoices,
      loading,
      headers,
      downloadInvoice,
      isPaymentTableFooterHidden,
      DEFAULT_MAX_ITEMS_PER_PAGE,
    }
  },
})
