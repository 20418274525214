




















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: {
    'FormContainer': () => import('@/components/utils/FormContainer.vue'),
    'CreateDatasetFromExistingForm':
      () => import('@/components/organisms/CreateDatasetFromExistingForm.vue'),
    'CreateDatasetFromUploadFormd-form':
      () => import('@/components/organisms/CreateDatasetFromUploadForm.vue'),
  },
})
