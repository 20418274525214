import { useMutation } from '@vue/apollo-composable'
import { ALL_DATASETS, DELETE_DATASET } from '@/core/graphql/datasets-gql'
import { Dataset } from '@/core/types/entities/dataset/dataset'

export const useDeleteDataset = () => {
  const { mutate: deleteDataset, onDone } = useMutation(
    DELETE_DATASET,
    () => ({
      update: (cache, { data: { deleteDataset: { dataset } } }) => {
        try {
          const data = cache.readQuery<{ datasets: Dataset[] }>(
            { query: ALL_DATASETS })
          if (data?.datasets) {
            data.datasets = data.datasets.filter(w => w.id !== dataset.id)
            cache.writeQuery({ query: ALL_DATASETS, data })
          }
        } catch {}
      },
    }),
  )

  /**
   * Unwrap the result
   */
  const onDoneStub = (fn: ((dataset: Dataset) => void)) => {
    return onDone(result => {
      if (result) {
        const dataset = result?.data?.deleteDataset?.dataset as Dataset
        fn(dataset)
      }
    })
  }

  return { deleteDataset, onDone: onDoneStub }
}
