import { useQuery, useResult } from '@vue/apollo-composable'

import { Product } from '@/core/types/entities/product/product'
import { PRODUCT } from '@/core/graphql/products-gql'

export const useProduct = (id: string) => {
  const { result, loading, error } = useQuery(PRODUCT, { id: id })
  const product = useResult<Product, null>(result, null)
  return { product, loading, error }
}
