import { analytics } from '@/utils/analytics/base'
import { getEventName } from '@/utils/analytics/common'
import { ADD_ACTION, REMOVE_ACTION } from '@/utils/analytics/actions'

const CONNECTION_SUBJECT = 'Connection'

export const trackConnectionAdd = (name: string) => {
  const eventName = getEventName(CONNECTION_SUBJECT, ADD_ACTION)
  analytics().track(eventName, { name })
}

export const trackConnectionRemove = (name: string) => {
  const eventName = getEventName(CONNECTION_SUBJECT, REMOVE_ACTION)
  analytics().track(eventName, { name: name })
}
