import {
  useRandomState,
  validateState,
} from '@/core/utils/github-connect/state'
import { GITHUB_APP_ID } from '@/config'
import { githubAuthenticate } from '@/core/services/user-service'
import { CustomRoute } from '@/router/router'

const SCOPES = ['repo', 'admin:public_key', 'user:email']
const GITHUB_OAUTH_BASE_URL = 'https://github.com/login/oauth/authorize'

export const generateGithubOauthUrl = () => {
  const params = {
    client_id: GITHUB_APP_ID,
    scope: SCOPES.join(' '),
    state: useRandomState(),
  }
  return `${GITHUB_OAUTH_BASE_URL}?${new URLSearchParams(params).toString()}`
}

export const processGithubOauthResponse = (query: CustomRoute['query']) => {
  const { code, state } = query
  validateState(state as string) // TODO: fix this
  return githubAuthenticate({ code, state })
}

export const DISCONNECT_INFO_TITLE = 'Success'

export const DISCONNECT_INFO_CONTENT = `
We successfully deleted the token associated to your GitHub account.
However, to give you total guarantee that we won't access your account\
 on your behalf anymore, we advise you to revoke the permissions you\
 originally gave us, directly from your GitHub account.

Go to github.com > settings > applications. Then, under the Authorized\
 Oauth Apps tab, choose our application and click Revoke.
`
