import { CheckoutSession } from '@/core/types/entities/billing/checkout-session'
import { analytics } from '@/utils/analytics/base'
import { getEventName } from '@/utils/analytics/common'
import { BUY_ACTION, CANCEL_ACTION } from '@/utils/analytics/actions'

const SUBJECT_PRODUCT = 'Product'
const SUBJECT_SUBSCRIPTION = `${SUBJECT_PRODUCT} Subscription`
const SUBJECT_CREDIT = `${SUBJECT_PRODUCT} Credit`
const SUBJECT_PAYMENT = 'Payment'

const isValidPayment = (status: CheckoutSession['payment_status']) => (
  ['paid', 'no_payment_required'].includes(status))

export const trackCharge = (session: CheckoutSession) => {
  if (isValidPayment(session.payment_status)) {
    switch (session.mode) {
      case 'payment':
        trackPurchaseCredit(session)
        break
      case 'subscription':
        trackPurchaseSubscription(session)
        break
    }
  } else trackCancelPayment(session)
}

const extractCheckoutSessionBaseProps = (session: CheckoutSession) => ({
  session_id: session.id,
  amount: session.amount_total / 100,
  amount_discount: session.total_details?.amount_discount,
  amount_tax: session.total_details?.amount_tax,
  currency: session.currency,
  payment_status: session.payment_status,
})

const extractSubscriptionProps = (session: CheckoutSession) => ({
  subscription: session.subscription,
})

export const trackPurchase = (
  session: CheckoutSession, name: string, props?: any) => {
  analytics().track(name, {
    ...extractCheckoutSessionBaseProps(session),
    ...props,
  })
}

const trackPurchaseSubscription = (session: CheckoutSession) => {
  const eventName = getEventName(`${SUBJECT_SUBSCRIPTION}`, `${BUY_ACTION}`)
  trackPurchase(session, eventName, extractSubscriptionProps(session))
}

const trackPurchaseCredit = (session: CheckoutSession) => {
  const eventName = getEventName(`${SUBJECT_CREDIT}`, `${BUY_ACTION}`)
  trackPurchase(session, eventName)
}

const trackCancelPayment = (session: CheckoutSession) => {
  const eventName = getEventName(SUBJECT_PAYMENT, CANCEL_ACTION)
  trackPurchase(session, eventName)
}
