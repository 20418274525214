import gql from 'graphql-tag'

const invoiceFields = gql`
  fragment invoiceFields on Invoice {
    id
    created
    amountDue
    amountPaid
    currency
    customerEmail
    invoicePdf
    number
    paid
    status
  }
`

export const ALL_INVOICES = gql`
  query invoices($userId: String) {
    invoices (userId: $userId) {
      ...invoiceFields
    }
  }
  ${invoiceFields}
`
