import { useQuery, useResult } from '@vue/apollo-composable'

import { Invoice } from '@/core/types/entities/billing/invoice'
import { ALL_INVOICES } from '@/core/graphql/billing/invoice'

export const useInvoices = () => {
  const { result, loading, error } = useQuery(ALL_INVOICES)
  const invoices = useResult<Invoice[], Invoice[]>(result, [])
  return { invoices, loading, error }
}
