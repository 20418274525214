






import { defineComponent } from '@vue/composition-api'
import { useAppBar } from '@/composables'
import { useCurrentUser } from '@/composables/entities/users/use-current-user'

export default defineComponent({
  components: {
    'UpdateUserProfileForm':
      () => import('@/components/organisms/UpdateUserProfileForm.vue'),
  },
  setup () {
    const { setAppBar } = useAppBar()
    setAppBar({ title: { label: 'Profile' } })

    const { currentUser } = useCurrentUser()

    return {
      currentUser,
    }
  },

})
