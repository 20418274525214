import InfoMessage from '@/components/organisms/InfoMessage.vue'
import { useModal } from '@/composables/use-modals'

type ShowInfoModalProps = {
  title?: string
  content: string
  action?: () => void
}

export const useInfoModal = () => {
  const { show, reset } = useModal()

  const showInfoModal = (props: ShowInfoModalProps) => {
    const component = {
      component: InfoMessage,
      props: {
        title: props.title,
        content: props.content,
        action: () => {
          if (props.action) props.action()
          reset()
        },
      },
    }
    show({ component })
  }

  return {
    showInfoModal,
  }
}
