import { useMutation } from '@vue/apollo-composable'

import {
  ALL_MINIMAL_PROJECTS,
  DELETE_PROJECT,
} from '@/core/graphql/projects-gql'
import { Project } from '@/core/types/entities/project/project/project'

export const useDeleteProject = () => {
  const { mutate: deleteProject, onDone } = useMutation(
    DELETE_PROJECT,
    () => ({
      update: (cache, { data: { deleteProject: { project } } }) => {
        try {
          const data = cache.readQuery<{ projects: Project[] }>(
            { query: ALL_MINIMAL_PROJECTS })
          if (data?.projects) {
            data.projects = data.projects.filter(p => p.id !== project.id)
            cache.writeQuery({ query: ALL_MINIMAL_PROJECTS, data })
          }
        } catch { }
      },
    }),
  )

  /**
   * Unwrap the result
   */
  const onDoneStub = (fn: ((project: Project) => void)) => {
    return onDone(result => {
      if (result) {
        const project = result?.data?.deleteProject?.project as Project
        fn(project)
      }
    })
  }

  return { deleteProject, onDone: onDoneStub }
}
