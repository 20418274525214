import { Store } from 'vuex'
import { InjectionKey, provide, inject } from '@vue/composition-api'
import { RootState } from '@/store'

export const StoreSymbol: InjectionKey<string> = Symbol('store')

export const provideStore = (store: Store<RootState>): void => {
  provide<Store<RootState>>(StoreSymbol, store)
}

export const useStore = (): Store<RootState> => {
  const store: Store<RootState> | void = inject<Store<RootState>>(StoreSymbol)
  if (!store) {
    throw new Error('Couldn\'t find store')
  }
  return store
}
