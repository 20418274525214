











import { defineComponent } from '@vue/composition-api'

import { useCurrentUser } from '@/composables/entities/users/use-current-user'

export default defineComponent({
  components: {
    'SSHKeys': () => import('@/components/organisms/SSHKeys.vue'),
  },
  setup () {
    const { currentUser } = useCurrentUser()

    return {
      currentUser,
    }
  },
})
