import { useQuery, useResult } from '@vue/apollo-composable'
import { DATASET } from '@/core/graphql/datasets-gql'
import {
  useUpdateDataset,
  useDeleteDataset,
  useMountDataset,
  useUnmountDataset,
} from '.'
import { Dataset } from '@/core/types/entities/dataset/dataset'

export const useDataset = (id: string) => {
  const { result, loading, error } = useQuery<Dataset>(DATASET, { id: id })
  const dataset = useResult(result, null)
  const { deleteDataset, onDone: onDoneDelete } = useDeleteDataset()

  const { update } = useUpdateDataset()
  const { mount, onDoneMount } = useMountDataset()
  const mountDataset = (projectId: string) => mount(id, projectId)

  const { unmount, onDoneUnmount } = useUnmountDataset()
  const unmountDataset = (projectId: string) => unmount(id, projectId)

  return {
    dataset,
    loading,
    error,
    update: (name: Dataset['name']) => update({ id: id, name: name }),
    mount: mountDataset,
    onDoneMount,
    unmount: unmountDataset,
    onDoneUnmount,
    deleteDataset: () => deleteDataset({ id: id }),
    onDoneDelete,
  }
}
