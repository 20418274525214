import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/es5/util/colors'

Vue.use(Vuetify)

const themeColors = {
  primary: colors.pink.accent2,
}

export const vuetify = new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        ...themeColors,
      },
      dark: {
        ...themeColors,
      },
    },
  },
  icons: {
    iconfont: 'fa',
  },
})
