import { useMutation } from '@vue/apollo-composable'

import { UNMOUNT_DATASET } from '@/core/graphql/datasets-gql'
import { PROJECT } from '@/core/graphql/projects-gql'
import { Project } from '@/core/types/entities/project/project/project'
import { Dataset } from '@/core/types/entities/dataset/dataset'

export const useUnmountDataset = () => {
  const {
    mutate: unmount,
    loading,
    onDone,
  } = useMutation<{ unmountDataset: { dataset: Dataset, project: Project } }>(
    UNMOUNT_DATASET,
    () => ({
      update: (cache, result) => {
        if (result.data?.unmountDataset) {
          const { unmountDataset: { dataset, project } } = result.data
          const cacheData = cache.readQuery<{ project: Project }>(
            { query: PROJECT, variables: { id: project.id } })
          if (cacheData) {
            cacheData.project.mountedDatasets = (
              cacheData.project.mountedDatasets.filter(
                d => d.id !== dataset.id))
            cache.writeQuery({
              query: PROJECT,
              variables: { id: project.id },
              data: cacheData,
            })
          }
        }
      },
    }),
  )

  const onDoneStub = (fn: ((dataset: Dataset, project: Project) => void)) => {
    return onDone(result => {
      const dataset = result?.data?.unmountDataset?.dataset
      const project = result?.data?.unmountDataset?.project
      if (dataset && project) fn(dataset, project)
    })
  }

  const unmountDataset = (id: Dataset['id'], projectId: Project['id']) => {
    return unmount({ id: id, projectId: projectId })
  }

  return {
    unmount: unmountDataset,
    unmountLoading: loading,
    onDoneUnmount: onDoneStub,
  }
}
