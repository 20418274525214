import { GetterTree, MutationTree, ActionTree, Module } from 'vuex'
import { RootState } from '.'
import { ModalType } from '@/core/types/modal'

const RESET_MODAL = 'Reset modal'
const UPDATE_MODAL = 'Update modal'
const ACTION_RESET_MODAL = 'Reset modal'
const ACTION_UPDATE_MODAL = 'Update modal'

type State = {
  modal: ModalType
}

const defaultState: State = {
  modal: {
    isActive: undefined,
  },
}

const getters = <GetterTree<State, RootState>> {
  modal: state => {
    return state.modal
  },
}

const mutations = <MutationTree<State>> {
  [RESET_MODAL] (state) {
    state.modal = { isActive: undefined }
  },
  [UPDATE_MODAL] (state, alertPartial) {
    state.modal = { ...state.modal, ...alertPartial }
  },
}

const actions = <ActionTree<State, RootState>> {
  [ACTION_RESET_MODAL] ({ commit }) {
    commit(RESET_MODAL)
  },
  [ACTION_UPDATE_MODAL] ({ commit }, alertPartial: Partial<ModalType>) {
    commit(UPDATE_MODAL, alertPartial)
  },
}

export const modalModule: Module<State, RootState> = {
  namespaced: false,
  state: defaultState,
  getters: getters,
  mutations: mutations,
  actions: actions,
}
