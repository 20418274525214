import Vue from 'vue'
import VueRouter from 'vue-router'
import { store } from '@/store'
import {
  Login,
  GithubConnect,
  PaymentSuccessful,
  PaymentCancelled,
  MagicLink,
} from '@/components/views'
import { DefaultLayout, LoginLayout } from '@/components/layouts'
import UserProfile from '@/components/views/UserProfile.vue'
import { isUserAuthenticated } from '@/utils/auth'
import { accountRoutes } from '@/router/account'
import { settingsRoutes } from '@/router/settings'
import { projectRoutes } from '@/router/project-routes'
import { datasetRoutes } from '@/router/dataset-routes'
import { trackPage } from '@/utils/analytics'
import { ACCOUNT_CATEGORY } from '@/utils/analytics/categories'
import * as qs from 'qs'
import { CustomRoute, CustomRouteConfig, CustomRouter } from '@/router/router'

Vue.use(VueRouter)

export const routes: CustomRouteConfig[] = [
  {
    path: '/',
    name: 'home',
    redirect: { name: 'Project.index' },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      layout: LoginLayout,
      tracking: {
        name: 'Login',
      },
    },
  },
  {
    path: '/magic-link',
    name: 'magic_link',
    component: MagicLink,
    meta: {
      layout: LoginLayout,
      tracking: {
        name: 'Magic Link',
      },
      public: true,
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: UserProfile,
    meta: {
      tracking: {
        category: ACCOUNT_CATEGORY,
        name: 'User Profile',
      },
    },
  },
  {
    path: '/oauth/github',
    name: 'github auth',
    component: GithubConnect,
    meta: {
      public: true,
    },
  },
  {
    path: '/payment-successful',
    name: 'payment_successful',
    component: PaymentSuccessful,
  },
  {
    path: '/payment-canceled',
    name: 'payment_cancelled',
    component: PaymentCancelled,
  },
  ...accountRoutes,
  ...settingsRoutes,
  ...projectRoutes,
  ...datasetRoutes,
  {
    path: '*',
    redirect: { name: 'Project.index' },
  },
]

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  stringifyQuery: query => {
    const result = qs.stringify(query, { encode: false })
    return result ? ('?' + result) : ''
  },
}) as CustomRouter

const isRedirectToLogin = (route: CustomRoute) => (
  route.name !== 'login' && !isUserAuthenticated() && !route.meta?.public
)

const isRedirectToHome = (route: CustomRoute) => (
  route.name === 'login' && isUserAuthenticated()
)

const areRoutesEqual = (from: CustomRoute, to: CustomRoute) => (
  from.name === to.name
)

router.beforeEach((to, from, next) => {
  // Prevent to reset app bar when changing query params
  // (with router.replace for example)
  if (!areRoutesEqual(from, to)) {
    store.dispatch('resetAppBar')
    store.dispatch('resetAlert')
  }

  if (isRedirectToLogin(to)) {
    next({ name: 'login' })
  } else if (isRedirectToHome(to)) {
    next({ name: '' })
  } else next()
})

router.afterEach(to => {
  const toLayout = to.meta && to.meta.layout ? to.meta.layout : DefaultLayout
  store.dispatch('changeLayout', toLayout)
  if (to.meta.tracking) {
    /*
    We have to override segment's setting of these props because segment uses
    window.location which is not set to the 'to' route yet
    */
    const props = {
      path: to.path,
      url: `${window.location.origin}${to.path}`,
    }
    trackPage(to.meta.tracking.category, to.meta.tracking.name, props)
  }
})
