import { GetterTree, MutationTree, ActionTree, Module } from 'vuex'
import { RootState } from '.'
import {
  SnackBarProps,
  SnackbarState,
} from '@/core/types/snackbar'

const defaultState: SnackbarState = {
  isActive: false,
  props: {
    message: '',
    config: {},
  },
}

const getters = <GetterTree<SnackbarState, RootState>> {
  isActive: state => {
    return state.isActive
  },
  props: state => {
    return state.props
  },
}

export const SHOW_SNACKBAR = 'Show snackbar'
export const HIDE_SNACKBAR = 'Hide snackbar'
export const ACTION_SHOW_SNACKBAR = 'Show snackbar'
export const ACTION_HIDE_SNACKBAR = 'Hide snackbar'

const mutations = <MutationTree<SnackbarState>> {
  [SHOW_SNACKBAR] (state, props: SnackBarProps) {
    state.isActive = true
    state.props = { ...state.props, ...props }
  },
  [HIDE_SNACKBAR] (state) {
    state.isActive = false
    state.props = defaultState.props
  },
}

const actions = <ActionTree<SnackbarState, RootState>> {
  [ACTION_SHOW_SNACKBAR] ({ commit }, props: SnackBarProps) {
    commit(SHOW_SNACKBAR, props)
  },
  [ACTION_HIDE_SNACKBAR] ({ commit }) {
    commit(HIDE_SNACKBAR)
  },
}

export const snackBarModule: Module<SnackbarState, RootState> = {
  namespaced: false,
  state: defaultState,
  getters: getters,
  mutations: mutations,
  actions: actions,
}
