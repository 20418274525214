/**
 *
 * Try to load an environment variable, throw error if it fails
 */
const loadEnvironmentVariable = (name: string) => {
  const value = process.env[name]
  if (typeof value === 'undefined') {
    throw new Error(`'${name} not set'`)
  } else {
    return value
  }
}

export const APP_NAME = process.env.VUE_APP_NAME || 'nibble.ai'
export const API_HOSTNAME = process.env.VUE_APP_API_HOSTNAME
export const KEEP_ALIVE_INTERVAL = (
  Number(process.env.VUE_APP_KEEP_ALIVE_INTERVAL_IN_SECONDS) || 15 * 60) * 1000
export const GITHUB_APP_ID = loadEnvironmentVariable('VUE_APP_GITHUB_APP_ID')
export const STRIPE_API_KEY = loadEnvironmentVariable('VUE_APP_STRIPE_API_KEY')
export const STRIPE_PRODUCT_SUBSCRIPTION_ID = loadEnvironmentVariable(
  'VUE_APP_STRIPE_PRODUCT_SUBSCRIPTION_ID',
)
export const STRIPE_PRODUCT_CREDIT_ID = loadEnvironmentVariable(
  'VUE_APP_STRIPE_PRODUCT_CREDIT_ID',
)
export const SEGMENT_KEY = loadEnvironmentVariable('VUE_APP_SEGMENT_KEY')
export const NOTEBOOK_CONVERTER_SERVICE_URL = loadEnvironmentVariable(
  'VUE_APP_NOTEBOOK_CONVERTER_SERVICE_URL',
)
