export type SnackbarState = {
  isActive: boolean
  props: SnackBarProps
}

export type SnackBarProps = {
  message: string
  config?: Partial<SnackBarConfig>
}

export type SnackBarConfig = {
  color: SnackBarColor
  timeout: SnackBarTimeout
  actionLabel: SnackBarActionLabel
  actionLabelColor: SnackBarActionLabelColor
  progressColor: SnackbarProgressColor
  icon: SnackBarContentIcon
}

export enum SnackBarColor {
  ERROR = '',
  SUCCESS = '',
}

export type SnackBarTimeout = 4000

export enum SnackBarActionLabel {
  OK = 'Ok',
}

export enum SnackBarActionLabelColor {
  WHITE = 'rgba(255,255,255)',
}

export enum SnackbarProgressColor {
  PRIMARY = 'primary',
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum SnackBarContentIcon {
  ERROR = 'fas fa-times',
  SUCCESS = 'fas fa-check',
}
