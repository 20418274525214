import { getOrThrowLocalStorage } from '@/core/services/local-storage'
import { useLogoutUser } from '@/composables/entities/users/use-signout'
import { router } from '@/router'
import { Cookies, COOKIE_TOKEN } from '@/utils/cookies'

export const useLocalStorage = () => {
  const { logout } = useLogoutUser()
  const listenLocalStorageChange = () => {
    window.onstorage = async () => {
      try {
        const userLocalStorage =
          getOrThrowLocalStorage<{ loggedIn: boolean }>('user')
        if (!userLocalStorage.loggedIn) {
          await logout()
        }
      } catch (e) {
        Cookies.remove(COOKIE_TOKEN)
        router.push({ name: 'login' })
      }
    }
  }
  return {
    listenLocalStorageChange,
  }
}
