import { SnackBarActionLabel, SnackBarActionLabelColor,
  SnackBarColor, SnackBarContentIcon, SnackbarProgressColor,
  SnackBarProps,
} from '@/core/types/snackbar'
import { ACTION_HIDE_SNACKBAR, ACTION_SHOW_SNACKBAR } from '@/store/snackbar'
import { computed } from '@vue/composition-api'
import { store } from '@/store'

export const useSnackbar = () => {
  const showError = (props: Pick<SnackBarProps, 'message'>) => {
    show({
      config: {
        color: SnackBarColor.ERROR,
        timeout: 4000,
        actionLabel: SnackBarActionLabel.OK,
        actionLabelColor: SnackBarActionLabelColor.WHITE,
        progressColor: SnackbarProgressColor.ERROR,
        icon: SnackBarContentIcon.ERROR,
      },
      ...props,
    })
  }

  const showSuccess = (message: string) => {
    show({
      config: {
        color: SnackBarColor.SUCCESS,
        timeout: 4000,
        actionLabel: SnackBarActionLabel.OK,
        actionLabelColor: SnackBarActionLabelColor.WHITE,
        progressColor: SnackbarProgressColor.SUCCESS,
        icon: SnackBarContentIcon.SUCCESS,
      },
      message,
    })
  }

  const show = (props: SnackBarProps) => {
    store.dispatch(ACTION_SHOW_SNACKBAR, props)
  }

  const hide = () => {
    store.dispatch(ACTION_HIDE_SNACKBAR)
  }

  return {
    hide,
    showError,
    showSuccess,
    isActive: computed<boolean>(() => store.getters.isActive),
    props: computed<SnackBarProps>(() => store.getters.props),
  }
}
