import gql from 'graphql-tag'

import {
  datasetFields,
  projectFields,
} from '@/core/graphql/fragments'

export const DATASET = gql`
  query getDataset($id: String!) {
    dataset (id: $id) {
      ...datasetFields
    }
  }
  ${datasetFields}
`

export const ALL_DATASETS = gql`
  query allDatasets($ownerId: String) {
    datasets (ownerId: $ownerId) {
      ...datasetFields
    }
  }
  ${datasetFields}
`

export const CREATE_DATASET = gql`
  mutation createDataset ($name: String!,
                          $storageType: StorageType!,
                          $connectionParams: DatasetConnectionParamsInput!) {
    createDataset (name: $name,
                   storageType: $storageType,
                   connectionParams: $connectionParams) {
      dataset {
        ...datasetFields
      }
    }
  }
  ${datasetFields}
`

export const UPDATE_DATASET = gql`
  mutation updateDataset ($id: String!, $name: String) {
    updateDataset (id: $id, name: $name) {
      dataset {
        id,
        name
      }
    }

  }
`

export const DELETE_DATASET = gql`
  mutation deleteDataset ($id: String!) {
    deleteDataset (id: $id) {
      dataset {
        id
      }
    }
  }
`

export const MOUNT_DATASET = gql`
  mutation mountDataset ($id: String!, $projectId: String!) {
    mountDataset (id: $id, projectId: $projectId) {
      dataset {
        ...datasetFields
      },
      project {
        ...projectFields
      }
    }
  }
  ${datasetFields}
  ${projectFields}
`

export const UNMOUNT_DATASET = gql`
  mutation umountDataset ($id: String!, $projectId: String!) {
    unmountDataset (id: $id, projectId: $projectId) {
      dataset {
        ...datasetFields
      },
      project {
        ...projectFields
      }
    }
  }
  ${datasetFields}
  ${projectFields}
`
