import Vue from 'vue'
import VueProxy from 'vue/types'
import Vuex from 'vuex'

import { appBarModule } from '@/store/appbar'
import { settingsModule } from '@/store/settings'
import { alertModule } from '@/store/alert'
import { modalModule } from '@/store/modal'
import { snackBarModule } from '@/store/snackbar'
import { drawerModule } from '@/store/drawer'

Vue.use(Vuex)

const LOGOUT_USER = 'Logout user'
const SET_AUTHENTICATED = 'Set authenticated'
const CHANGE_LAYOUT = 'Change layout'

export type RootState = {
  layout?: VueProxy,
  isAuthenticated?: boolean
}

export const store = new Vuex.Store<RootState>({
  state: {
    layout: undefined,
    isAuthenticated: undefined,
  },
  getters: {
    layout: state => {
      return state.layout
    },
    isAuthenticated: state => {
      return !!state.isAuthenticated
    },
  },
  mutations: {
    [CHANGE_LAYOUT] (state, newLayout) {
      state.layout = newLayout
    },
    [LOGOUT_USER] (state) {
      state.isAuthenticated = false
    },
    [SET_AUTHENTICATED] (state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated
    },
  },
  actions: {
    changeLayout ({ commit }, newLayout) {
      commit(CHANGE_LAYOUT, newLayout)
    },
    loginUser ({ commit }) { commit(SET_AUTHENTICATED, true) },
    logoutUser ({ commit }) { commit(SET_AUTHENTICATED, false) },
    setAuthenticated ({ commit }, isAuthenticated: boolean) {
      commit(SET_AUTHENTICATED, isAuthenticated)
    },
  },
  modules: {
    appBar: appBarModule,
    settings: settingsModule,
    alert: alertModule,
    modal: modalModule,
    snackbar: snackBarModule,
    drawer: drawerModule,
  },
})

store.dispatch('initSettings')
