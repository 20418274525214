






import { defineComponent } from '@vue/composition-api'
import { useAppBar } from '@/composables'

export default defineComponent({
  components: {
    'FormContainer': () => import('@/components/utils/FormContainer.vue'),
    'CreateProjectForm':
      () => import('@/components/organisms/CreateProjectForm.vue'),
  },
  setup () {
    const { setAppBar } = useAppBar()
    setAppBar({ title: { label: 'Create project' } })
  },
})
