import cookie from 'cookie_js'

/* type definition for 'cookie_js' is lacking so we're monkey patching */
type Options = typeof cookie.defaults
type OptionsOverride = Options & { sameSite?: string }

const newDefaultOptions: Partial<OptionsOverride> = {
  sameSite: 'Strict',
  secure: process.env.NODE_ENV === 'production',
}

cookie.defaults = {
  ...cookie.defaults,
  ...newDefaultOptions,
} as OptionsOverride

export const Cookies = cookie
export const COOKIE_TOKEN = 'token'
export const COOKIE_TOKEN_DURATION = 30
