import { useMutation } from '@vue/apollo-composable'

import { UNMOUNT_PROJECT } from '@/core/graphql/projects-gql'
import { Project } from '@/core/types/entities/project/project/project'

export const useUnmountProject = () => {
  const {
    mutate,
    onDone,
    loading,
  } = useMutation<{ unmountProject: { project: Project } }>(UNMOUNT_PROJECT)

  const unmount = (id: Project['id']) => (
    mutate({ id: id }))

  const onDoneStub = (fn: (project?: Project) => any) => (
    onDone(result => fn(result?.data?.unmountProject?.project)))

  return {
    unmount,
    onDone: onDoneStub,
    loading,
  }
}
