import { useRouter } from '@/composables'
import { DomainSpecificEntity } from '@/core/types/entities/entity'

export const useShowEntity = () => {
  const router = useRouter()
  return (entity: DomainSpecificEntity) => {
    router.push({
      name: `${entity.__typename}.show`,
      params: {
        id: entity.id,
      },
    })
  }
}
