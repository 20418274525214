import { useMutation } from '@vue/apollo-composable'
import { ALL_DATASETS, CREATE_DATASET } from '@/core/graphql/datasets-gql'
import { Dataset } from '@/core/types/entities/dataset/dataset'

export const useCreateDataset = () => {
  const { mutate: create, onDone } = useMutation(
    CREATE_DATASET,
    () => ({
      update: (cache, { data: { createDataset: { dataset } } }) => {
        try {
          const data = cache.readQuery<{ datasets: Dataset[] }>(
            { query: ALL_DATASETS })
          if (data?.datasets) {
            data.datasets.push(dataset)
            cache.writeQuery({ query: ALL_DATASETS, data })
          }
        } catch {}
      },
    }),
  )

  /**
   * Unwrap the result
   */
  const onDoneStub = (fn: ((dataset: Dataset) => void)) => {
    return onDone(result => {
      if (result) {
        const dataset = result?.data?.createDataset?.dataset as Dataset
        fn(dataset)
      }
    })
  }

  return { create, onDone: onDoneStub }
}
