var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DataTable',{attrs:{"headers":_vm.headers,"items":_vm.invoices,"loading":_vm.loading,"sort-by":['created'],"sort-desc":[true],"hide-default-footer":_vm.isPaymentTableFooterHidden,"items-per-page":_vm.DEFAULT_MAX_ITEMS_PER_PAGE},scopedSlots:_vm._u([{key:"item.invoicePdf",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.downloadInvoice(item)}}},[_c('v-icon',{attrs:{"left":"","color":"primary"}},[_vm._v("far fa-file-pdf")])],1)]}},{key:"item.amountDue",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.amountDue)+"€")])]}},{key:"item.amountPaid",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.amountPaid)+"€")])]}},{key:"item.paid",fn:function(ref){
var item = ref.item;
return [(item.paid)?_c('v-icon',{attrs:{"small":""}},[_vm._v(" fas fa-check ")]):_vm._e()]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("momentFormat")(item.created,'MMMM Do YYYY')))])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }