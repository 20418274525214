import { computed } from '@vue/composition-api'
import {
  useQuery,
  useResult,
  useSubscription,
} from '@vue/apollo-composable'

import {
  PROJECT,
  SUBSCRIBE_TO_PROJECT,
} from '@/core/graphql/projects-gql'
import {
  useDeleteProject,
} from '@/composables/entities/projects/project/use-delete-project'
import {
  useMountProject,
} from '@/composables/entities/projects/project/use-mount-project'
import {
  useUnmountProject,
} from '@/composables/entities/projects/project/use-unmount-project'
import {
  ProjectRaw,
  Project as ProjectType,
} from '@/core/types/entities/project/project/project'
import { Project } from '@/core/models/project/project'

export const useProject = (id: ProjectRaw['id']) => {
  const {
    result,
    loading,
    error,
    onResult,
  } = useQuery<{project: ProjectRaw}>(PROJECT, { id: id })
  const project = computed(() => Project(useResult(result, null).value))

  const {
    mount,
    loading: mountLoading,
    onDone: onDoneMount,
  } = useMountProject()
  const {
    unmount,
    loading: unmountLoading,
    onDone: onDoneUnmount,
  } = useUnmountProject()
  const { deleteProject, onDone: onDoneDelete } = useDeleteProject()
  useSubscription(SUBSCRIBE_TO_PROJECT, { id: id })

  const unmountWrapper = () => {
    if (project) {
      return unmount(id)
    }
  }

  const onResultStub = (fn: (project: ProjectType) => void) => {
    return onResult(result => {
      if (result) {
        const project = Project(result.data.project) as ProjectType
        fn(project)
      }
    })
  }

  return {
    project,
    onResult: onResultStub,
    loading,
    error,
    mount: () => mount(id),
    mountLoading,
    onDoneMount,
    unmount: unmountWrapper,
    unmountLoading,
    onDoneUnmount,
    deleteProject: () => deleteProject({ id: id }),
    onDoneDelete,
  }
}
