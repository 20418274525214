import _ from 'lodash'

import { useSubscription }
  from '@/composables/entities/products/use-subscription'
import { useBuyProduct } from '@/composables/entities/products/use-buy-product'

export const useBuySubscription = () => {
  const { subscription } = useSubscription()
  const { buy } = useBuyProduct()

  const buySubscription = (plan: string, freq: string) => {
    if (subscription?.value) {
      const monthlySubscription = _.find(
        subscription.value.prices, { nickname: `${plan}_${freq}` })
      if (monthlySubscription) buy(monthlySubscription.id)
    }
  }

  return {
    buySubscription,
  }
}
