import { GetterTree, MutationTree, ActionTree, Module } from 'vuex'
import { RootState } from '.'
import { Drawer } from '@/core/types/drawer'

const SET_DRAWER_VISIBILITY = 'Set drawer visibility'

type DrawerState = {
  drawer: {
    isVisible: boolean
  }
}

const defaultDrawer: Drawer = {
  isVisible: false,
}

const defaultState: DrawerState = { drawer: defaultDrawer }

const getters = <GetterTree<DrawerState, RootState>> {
  drawer: state => {
    return state.drawer
  },
}

const mutations = <MutationTree<DrawerState>> {
  [SET_DRAWER_VISIBILITY] (state, isVisible) {
    state.drawer = { isVisible: isVisible }
  },
}

const actions = <ActionTree<DrawerState, RootState>> {
  setDrawer ({ commit }, isVisible) {
    commit(SET_DRAWER_VISIBILITY, isVisible)
  },
}

export const drawerModule: Module<DrawerState, RootState> = {
  namespaced: false,
  state: defaultState,
  getters: getters,
  mutations: mutations,
  actions: actions,
}
