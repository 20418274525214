import {
  DatasetNew,
  DatasetView,
  DatasetIndex,
} from '@/components/views'
import { DATASET_CATEGORY } from '@/utils/analytics/categories'
import { PAGE_NEW, PAGE_VIEW, PAGE_INDEX } from '@/utils/analytics/pages'
import { CustomRouteConfig } from '@/router/router'

const BASE_ROUTE = 'datasets'
const ENTITY_NAME = 'Dataset'
const MAIN_MENU_LABEL = 'datasets'

export const datasetRoutes: CustomRouteConfig[] = [
  {
    path: `/${BASE_ROUTE}/new`,
    name: 'Dataset.new',
    component: DatasetNew,
    meta: {
      tracking: {
        category: DATASET_CATEGORY,
        name: PAGE_NEW,
      },
    },
  },
  {
    path: `/${BASE_ROUTE}/:id`,
    name: `${ENTITY_NAME}.show`,
    component: DatasetView,
    props: true,
    meta: {
      tracking: {
        category: DATASET_CATEGORY,
        name: PAGE_VIEW,
      },
    },
  },
  {
    path: `/${BASE_ROUTE}`,
    name: `${ENTITY_NAME}.index`,
    component: DatasetIndex,
    meta: {
      mainMenu: MAIN_MENU_LABEL,
      tracking: {
        category: DATASET_CATEGORY,
        name: PAGE_INDEX,
      },
    },
  },
]
