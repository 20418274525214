




























import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  props: {
    title: {
      type: String,
      required: false,
    },
    content: {
      type: String,
      required: true,
    },
    action: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  setup (props) {
    const handleAction = () => props.action && props.action()

    return {
      handleAction,
    }
  },
})
