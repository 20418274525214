


































import { computed, defineComponent, ref, PropType } from '@vue/composition-api'

export default defineComponent({
  props: {
    name: {
      type: String,
    },
    action: {
      type: Function as PropType<() => void>,
    },
  },
  setup (props) {
    const vmodel = ref<string>()

    const isValid = computed(() => vmodel.value === props.name)

    const handleAction = () => isValid.value && props.action && props.action()

    return {
      handleAction,
      vmodel,
      isValid,
    }
  },
})
