import ProjectView from '@/components/views/Project.vue'
import ProjectIndex from '@/components/views/ProjectIndex.vue'
import ProjectNew from '@/components/views/ProjectNew.vue'
import { PROJECT_CATEGORY } from '@/utils/analytics/categories'
import { PAGE_NEW, PAGE_VIEW, PAGE_INDEX } from '@/utils/analytics/pages'
import { CustomRouteConfig } from '@/router/router'

export const projectRoutes: CustomRouteConfig[] = [
  {
    path: '/projects/new',
    name: 'Project.new',
    component: ProjectNew,
    meta: {
      tracking: {
        category: PROJECT_CATEGORY,
        name: PAGE_NEW,
      },
    },
  },
  {
    path: '/projects/:id/:subView?',
    name: 'Project.show',
    component: ProjectView,
    props: true,
    meta: {
      tracking: {
        category: PROJECT_CATEGORY,
        name: PAGE_VIEW,
      },
    },
  },
  {
    path: '/projects',
    name: 'Project.index',
    component: ProjectIndex,
    meta: {
      mainMenu: 'projects',
      tracking: {
        category: PROJECT_CATEGORY,
        name: PAGE_INDEX,
      },
    },
  },
]
