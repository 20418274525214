import BuyCredit from '@/components/views/BuyCredit.vue'
import PaymentHistory from '@/components/views/settings/PaymentHistory.vue'
import {
  PRODUCT_CATEGORY,
  ACCOUNT_CATEGORY,
} from '@/utils/analytics/categories'
import { CustomRouteConfig } from '@/router/router'

export const accountRoutes: CustomRouteConfig[] = [
  {
    path: '/account',
    redirect: { name: 'profile' },
  },
  {
    path: '/account/buy-credits',
    name: 'buy_credits',
    component: BuyCredit,
    meta: {
      tracking: {
        category: PRODUCT_CATEGORY,
        name: 'Credits',
      },
    },
  },
  {
    path: '/account/payment-history',
    name: 'payment_history',
    component: PaymentHistory,
    meta: {
      tracking: {
        category: ACCOUNT_CATEGORY,
        name: 'Payment History',
      },
    },
  },
]
