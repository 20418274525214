import { HTTP, DefaultAPIResponseData } from '@/core/services/http-common'
import { User } from '@/core/types/entities/user/user'

const API_ROUTE_CREATE_CHALLENGE = '/auth/challenge'
const API_ROUTE_TOKEN_NEW = '/auth/token/new'
const API_ROUTE_GITHUB_AUTH = '/auth/github'

export type LoginResponse = {
  token: string,
  user: User
}

export const createChallenge = async (email: string) => {
  return HTTP.post<{ message: string }>(API_ROUTE_CREATE_CHALLENGE, { email })
}

export const loginUser = (email: string, loginCode: string) => {
  const payload = { email: email, login_code: loginCode }
  return HTTP.post<LoginResponse>(API_ROUTE_TOKEN_NEW, payload)
}

export const loginWithCredentials = (credentials: string) => {
  return HTTP.post<LoginResponse>(API_ROUTE_TOKEN_NEW, { credentials })
}

type githubAuthPayload = {
  code: string | (string | null)[],
  state: string | (string | null)[]
}

export const githubAuthenticate = (payload: githubAuthPayload) => {
  return HTTP.post<DefaultAPIResponseData>(API_ROUTE_GITHUB_AUTH, payload)
}
