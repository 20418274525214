






























import {
  computed,
  defineComponent,
  onMounted,
  onUpdated,
} from '@vue/composition-api'

import { useCurrentUser } from '@/composables/entities/users/use-current-user'
import
{ DISCONNECT_INFO_CONTENT,
  DISCONNECT_INFO_TITLE,
  generateGithubOauthUrl,
} from '@/core/utils/github-connect'
import { trackConnectionRemove } from '@/utils/analytics/connection'
import { useInfoModal } from '@/composables/use-modals/use-info-message'

export default defineComponent({
  setup () {
    const {
      currentUser,
      refetch: refetchCurrentUser,
      update,
      updateLoading,
    } = useCurrentUser()

    const { showInfoModal } = useInfoModal()

    const label = computed(
      () => currentUser.value?.hasEnabledGithub ? 'disconnect' : 'connect')

    const toggleGithub = () => {
      if (currentUser?.value?.hasEnabledGithub) disconnectGithub()
      else connectGithub()
    }

    const connectGithub = () => {
      const githubOauthUrl = generateGithubOauthUrl()
      window.open(githubOauthUrl, '_self')
    }

    const disconnectGithub = async () => {
      const res = await update({ resetGithubToken: true })
      if (res.data) {
        trackConnectionRemove('Github')
        refetchCurrentUser()
      }
      showInfoModal({
        title: DISCONNECT_INFO_TITLE,
        content: DISCONNECT_INFO_CONTENT,
      })
    }

    // This will allow currentUser's `hasEnabledGithub` to update
    // without using a subscription for this after we come back from
    // github authentication process
    onMounted(() => refetchCurrentUser())
    onUpdated(() => refetchCurrentUser())

    return {
      currentUser,
      label,
      update,
      updateLoading,
      toggleGithub,
    }
  },
})
