import { useQuery, useResult } from '@vue/apollo-composable'

import { Dataset } from '@/core/types/entities/dataset/dataset'
import { ALL_DATASETS } from '@/core/graphql/datasets-gql'

export const useDatasets = () => {
  const { result, loading, error } = useQuery(ALL_DATASETS)
  const datasets = useResult<Dataset[], Dataset[]>(result, [])
  return { datasets, loading, error }
}
