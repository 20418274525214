import gql from 'graphql-tag'

const subscriptionFields = gql`
  fragment subscriptionFields on StripeSubscription {
    id
    currentPeriodEnd
    amount
    currency
    interval
  }
`

export const SUBSCRIPTION = gql`
  query subscription($userId: String) {
    subscription (userId: $userId) {
      ...subscriptionFields
    }
  }
  ${subscriptionFields}
`
