import { useMutation } from '@vue/apollo-composable'
import { MOUNT_PROJECT } from '@/core/graphql/projects-gql'
import { Project } from '@/core/types/entities/project/project/project'

export const useMountProject = () => {
  const {
    mutate,
    onDone,
    loading,
  } = useMutation<{ mountProject: { project: Project } }>(MOUNT_PROJECT)

  const mountProject = (id: Project['id']) => {
    return mutate({ id: id })
  }

  const onDoneStub = (fn: ((project?: Project) => any)) => {
    return onDone(result => {
      const project = result?.data?.mountProject?.project
      fn(project)
    })
  }

  return {
    mount: mountProject,
    loading: loading,
    onDone: onDoneStub,
  }
}
