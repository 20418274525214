import Vue from 'vue'
import '@/plugins/composition-plugin'
import { provide } from '@vue/composition-api'
import { vuetify } from '@/plugins/vuetify'
import '@/plugins/highlightjs'
import { router } from '@/router'
import { store } from '@/store'
import { capitalize, fromNow, momentFormat } from '@/utils/filters'
import upperFirst from 'lodash/upperFirst'
import { isUserAuthenticated } from '@/utils/auth'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { apolloClient } from '@/core/services/http-common'
import { loadAnalytics } from '@/utils/analytics'

import App from '@/App.vue'

Vue.config.productionTip = false
Vue.config.devtools = process.env.NODE_ENV === 'development'

Vue.filter('capitalize', capitalize)
Vue.filter('upperFirst', upperFirst)
Vue.filter('fromNow', fromNow)
Vue.filter('momentFormat', momentFormat)

store.dispatch('setAuthenticated', isUserAuthenticated())

loadAnalytics()

new Vue({
  router,
  store,
  vuetify,
  setup () {
    provide(DefaultApolloClient, apolloClient)
    return {}
  },
  render: h => h(App),
}).$mount('#app')
