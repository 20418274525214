







































































import _ from 'lodash'
import moment from 'moment'
import { computed, defineComponent } from '@vue/composition-api'

import { useVuetify } from '@/composables'
import {
  useBuySubscription,
} from '@/composables/entities/products/use-buy-subscription'
import {
  useSubscription,
} from '@/composables/entities/billing/use-subscription'
import { useCurrentUser } from '@/composables/entities/users/use-current-user'

export default defineComponent({
  setup () {
    const { theme } = useVuetify()
    const { buySubscription } = useBuySubscription()
    const { currentUser } = useCurrentUser()
    const { subscription } = useSubscription()

    const textColor = computed(() => theme.dark ? 'white' : 'black')
    const cssClass = computed(
      () => `${textColor.value}--text`)

    const PLACEHOLDER = '-'
    // eslint-disable-next-line
    const formatOrFallback = <T extends any>(
      value?: T,
      fn?: (value: T) => string,
    ) => value ? (fn ? fn(value) : value) : PLACEHOLDER

    const subscriptionAmount = computed(
      () => formatOrFallback(subscription?.value?.amount, x => `${x}€`))
    const nextPaymentDue = computed(() => formatOrFallback(
      subscription.value?.currentPeriodEnd,
      v => moment(v).format('MMMM Do YYYY'),
    ))
    const cloudCredit = computed(() => formatOrFallback(
      currentUser.value?.cloudCredit, v => `${_.round(v, 2)}€`))
    const currentPlan = computed(
      () => formatOrFallback(currentUser.value?.plan, () => 'Pro'))

    return {
      currentUser,
      subscriptionAmount,
      nextPaymentDue,
      cloudCredit,
      currentPlan,
      buySubscription,
      textColor,
      cssClass,
    }
  },
})
