




import { defineComponent } from '@vue/composition-api'

import { usePaymentRedirect } from '@/composables/entities/billing'
import { trackCharge } from '@/utils/analytics/charge'
import { useAlert } from '@/composables/use-alert'

export default defineComponent({
  setup () {
    const { showAlertError } = useAlert()
    const { onBefore, onAfter } = usePaymentRedirect({ name: 'billing' })
    onBefore(session => session && trackCharge(session))
    onAfter(() => showAlertError('Payment cancelled...'))
  },
})
