import { EntityName } from '@/core/types/enums'
import { useRouter } from '.'
import { useAppBar } from '@/composables/use-appbar'

export const useIndexViewAppbar = (entityName: EntityName) => {
  const router = useRouter()
  const { setAppBar } = useAppBar()
  setAppBar({
    title: {
      label: entityName + 's',
    },
    button: {
      label: `New ${entityName}`,
      action: () => router.push({ name: `${entityName}.new` }),
    },
  })
  return {
    setAppBar,
  }
}
