import { useMutation } from '@vue/apollo-composable'

import { CREATE_CHECKOUT_SESSION } from '@/core/graphql/products-gql'

export const useCheckoutSession = () => {
  const { mutate, ...rest } = useMutation(CREATE_CHECKOUT_SESSION)

  return {
    mutate,
    ...rest,
  }
}
