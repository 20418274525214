import axios from 'axios'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { ApolloLink, split } from 'apollo-link'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import { PROTOCOLS } from '@/core/services/utils'
import { API_HOSTNAME } from '@/config'
import { Cookies } from '@/utils/cookies'
import { useSnackbar } from '@/composables/use-snackbar'
import { CustomError } from '@/core/models/custom-error'
import { ErrorType } from '@/core/types/entities/error/custom-error'
import { router } from '@/router'

export type DefaultAPIResponseData = {
  message: string
}

const BASE_URL = `${PROTOCOLS.HTTPS_PROTOCOL}://${API_HOSTNAME}`
const WSS_BASE_URL = `${PROTOCOLS.WSS_PROTOCOL}://${API_HOSTNAME}`
const GRAPHQL_ENDPOINT = '/graphql/'
const GRAPHQL_URL = BASE_URL + GRAPHQL_ENDPOINT
const WSS_GRAPHQL_URL = WSS_BASE_URL + GRAPHQL_ENDPOINT

const getJWTAuthorizationHeader = () => {
  const token = Cookies.get('token')
  return token ? `jwt ${token}` : ''
}

export const HTTP = axios.create({
  baseURL: BASE_URL,
})

HTTP.interceptors.request.use(
  config => {
    config.headers.Authorization = getJWTAuthorizationHeader()
    return config
  },
)

HTTP.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      router.push({ name: 'login' })
    }
    return Promise.reject(error)
  },
)

const httpLink = new HttpLink({
  uri: GRAPHQL_URL,
})

const wsLink = new WebSocketLink({
  uri: WSS_GRAPHQL_URL,
  options: {
    reconnect: true,
    connectionParams: {
      Authorization: getJWTAuthorizationHeader(),
    },
  },
})

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      Authorization: getJWTAuthorizationHeader(),
    },
  }
})

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink,
)

const { showError } = useSnackbar()

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach((error) => {
          window.console.log(
            `[GraphQL error]: Message: ${error.message},
            Location: ${error.locations}, Path: ${error.path}`,
          )
        },
        )
        const customError = CustomError({ message: graphQLErrors[0].message,
          code: graphQLErrors[0].extensions?.code,
          type: ErrorType.BACKEND_GRAPHQL })
        showError({ message: customError.message })
      }
      if (networkError) {
        window.console.log(`[Network error]: ${networkError}`)
        const customError = CustomError({ message: networkError.message,
          type: ErrorType.NETWORK })
        showError({ message: customError.message })
      }
    }),
    authLink.concat(link),
  ]),
  cache: new InMemoryCache(),
})
