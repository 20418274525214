var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DataTable',{attrs:{"headers":_vm.headers,"items":_vm.projects,"loading":_vm.loading,"hide-default-footer":_vm.isProjectTableFooterHidden,"items-per-page":_vm.DEFAULT_MAX_ITEMS_PER_PAGE},on:{"click:row":function($event){return _vm.showEntity($event)}},scopedSlots:_vm._u([{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.state))])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("fromNow")(item.createdAt)))])]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("fromNow")(item.updatedAt)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.isProjectMounted(item.state)
        || _vm.isProjectUnmounting(item.state))?[_c('v-btn',{attrs:{"depressed":"","color":"secondary","loading":_vm.isProjectUnmounting(item.state),"disabled":_vm.isProjectUnmounting(item.state)},on:{"click":function($event){$event.stopPropagation();return _vm.unmountProject(item.id)}}},[_vm._v(" Unmount ")])]:[_c('v-btn',{attrs:{"depressed":"","color":"secondary","loading":_vm.isProjectMounting(item.state),"disabled":_vm.isProjectMounting(item.state)},on:{"click":function($event){$event.stopPropagation();return _vm.mountProject(item.id)}}},[_vm._v(" Mount ")])]]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }