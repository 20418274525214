import { loadStripe } from '@stripe/stripe-js'

import { STRIPE_API_KEY } from '@/config'
import { useCheckoutSession }
  from '@/composables/entities/products/use-checkout-session'

export const useBuyProduct = () => {
  const { mutate } = useCheckoutSession()

  const buy = async (priceId: string) => {
    const res = await mutate({ priceIds: [priceId] })
    const sessionId = res.data.createStripeCheckoutSession.sessionId
    const stripe = await loadStripe(STRIPE_API_KEY)
    if (stripe) await stripe.redirectToCheckout({ sessionId: sessionId })
  }

  return {
    buy,
  }
}
