import { generateRandomString } from '@/utils/random-generator'
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from '@/core/services/local-storage'

export const GITHUB_CONNECT_STATE_STORAGE_KEY = 'github_connect_state'
const RANDOM_STATE_LENGTH = 32

const getState = () => {
  return getLocalStorage(GITHUB_CONNECT_STATE_STORAGE_KEY)
}

const setState = (value: string) => {
  setLocalStorage(GITHUB_CONNECT_STATE_STORAGE_KEY, value)
}

const removeState = () => {
  removeLocalStorage(GITHUB_CONNECT_STATE_STORAGE_KEY)
}

export const useRandomState = () => {
  const state = generateRandomString(RANDOM_STATE_LENGTH)
  setState(state)
  return state
}

export const validateState = (state: string) => {
  try {
    if (state !== getState()) {
      throw new Error('Invalid random state for Github Connect')
    }
  } finally {
    removeState()
  }
}
