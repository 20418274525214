import { InjectionKey, provide, inject } from '@vue/composition-api'
import { Route } from 'vue-router'

const routeKey: InjectionKey<string> = Symbol('route')

export const provideRoute = (route: Route) => {
  provide<Route>(routeKey, route)
}

export const useRoute = (key: InjectionKey<string> = routeKey): Route => {
  const route: Route | void = inject<Route>(key)
  if (!route) {
    throw new Error('Couldn\'t find route...')
  }
  return route
}
