import { Cookies, COOKIE_TOKEN } from '@/utils/cookies'

export const isUserAuthenticated = () => {
  const token = Cookies.get(COOKIE_TOKEN)
  return (
    token !== '' &&
    token != null &&
    typeof token !== 'undefined'
  )
}
