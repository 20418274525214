import { useQuery, useResult } from '@vue/apollo-composable'

import { ALL_MINIMAL_PROJECTS } from '@/core/graphql/projects-gql'
import { MinimalProject } from '@/core/types/entities/project/project/project'

const POLL_INTERVAL = 1000

export const useProjects = () => {
  const { result, ...rest } = useQuery(ALL_MINIMAL_PROJECTS, {}, {
    pollInterval: POLL_INTERVAL,
  })
  const projects = useResult<MinimalProject[], MinimalProject[]>(result, [])
  return { projects, ...rest }
}
