import { useQuery, useResult } from '@vue/apollo-composable'

import { Subscription } from '@/core/types/entities/billing/subscription'
import { SUBSCRIPTION } from '@/core/graphql/billing/subscription'

export const useSubscription = () => {
  const { result, loading, error } = useQuery(SUBSCRIPTION)
  const subscription = useResult<Subscription, null>(result, null)
  return { subscription, loading, error }
}
