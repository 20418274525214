import { computed } from '@vue/composition-api'

import { AppBar } from '@/core/types/appbar'
import { useStore } from '@/composables'

export const useAppBar = () => {
  const store = useStore()
  const setAppBar = (appBarPartial: Partial<AppBar> | undefined) => {
    if (appBarPartial) { store.dispatch('setAppBar', appBarPartial) }
  }
  const updateAppBar = (appBarPartial: Partial<AppBar> | undefined) => {
    if (appBarPartial) { store.dispatch('updateAppBar', appBarPartial) }
  }
  return {
    appBar: computed<AppBar>(() => store.getters.appBar),
    resetAppBar: () => store.dispatch('resetAppBar'),
    setAppBar,
    updateAppBar,
  }
}
