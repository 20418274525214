import { InjectionKey, provide, inject } from '@vue/composition-api'

import VueRouter from 'vue-router'

export const RouterSymbol: InjectionKey<string> = Symbol('router')

export const provideRouter = (router: VueRouter): void => {
  provide<VueRouter>(RouterSymbol, router)
}

export const useRouter = (): VueRouter => {
  const router: VueRouter | void = inject<VueRouter>(RouterSymbol)
  if (!router) {
    throw new Error('Couldn\'t find router...')
  }
  return router
}
