import { Alert, AlertTypes } from '@/core/types/alert'

import { store } from '@/store'

export const updateAlert = (alertPartial: Partial<Alert>) => {
  store.dispatch('updateAlert', alertPartial)
}

export const resetAlert = () => {
  store.dispatch('resetAlert')
}

export const showAlert = (alertPartial: Partial<Alert>) => {
  updateAlert({ ...alertPartial, isVisible: true })
}

const showAlertWithType = (type: AlertTypes) => {
  const closure = (message: string) => {
    return showAlert({ message: message, type: type })
  }
  return closure
}

export const showAlertSuccess = showAlertWithType('success')
export const showAlertWarning = showAlertWithType('warning')
export const showAlertError = showAlertWithType('error')
