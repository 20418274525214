import { Settings } from '@/components/views'
import Connections from '@/components/views/settings/Connections.vue'
import SSHKeys from '@/components/views/settings/SSHKeys.vue'
import Billing from '@/components/views/settings/Billing.vue'
import { SETTINGS_CATEGORY } from '@/utils/analytics/categories'
import { CustomRouteConfig } from '@/router/router'

export const settingsRoutes: CustomRouteConfig[] = [
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    meta: {
      mainMenu: false,
    },
    redirect: { name: 'connections' },
    children: [
      {
        path: 'connections',
        name: 'connections',
        component: Connections,
        meta: {
          tracking: {
            category: SETTINGS_CATEGORY,
            name: 'Connections',
          },
        },
      },
      {
        path: 'ssh-keys',
        component: SSHKeys,
        meta: {
          tracking: {
            category: SETTINGS_CATEGORY,
            name: 'SSH Keys',
          },
        },
      },
      {
        path: 'billing',
        name: 'billing',
        component: Billing,
        meta: {
          tracking: {
            category: SETTINGS_CATEGORY,
            name: 'Billing',
          },
        },
      },
    ],
  },
]
