import { useMutation } from '@vue/apollo-composable'
import { MOUNT_DATASET } from '@/core/graphql/datasets-gql'
import { PROJECT } from '@/core/graphql/projects-gql'
import { Dataset } from '@/core/types/entities/dataset/dataset'
import { Project } from '@/core/types/entities/project/project/project'

export const useMountDataset = () => {
  const {
    mutate,
    loading,
    onDone,
  } = useMutation<{ mountDataset: { dataset: Dataset, project: Project } }>(
    MOUNT_DATASET,
    () => ({
      update: (cache, { data }) => {
        if (data?.mountDataset) {
          const { mountDataset: { dataset, project } } = data
          const cacheData = cache.readQuery<{ project: Project }>(
            { query: PROJECT, variables: { id: project.id } })
          if (cacheData) {
            cacheData.project.mountedDatasets.push(dataset)
            cache.writeQuery({
              query: PROJECT,
              variables: { id: project.id },
              data: cacheData,
            })
          }
        }
      },
    }),
  )
  const mount = (id: Dataset['id'], projectId: Project['id']) => {
    return mutate({ id: id, projectId: projectId })
  }

  const onDoneStub = (fn: ((dataset: Dataset, project: Project) => void)) => {
    return onDone(result => {
      const dataset = result?.data?.mountDataset?.dataset
      const project = result?.data?.mountDataset?.project
      if (dataset && project) fn(dataset, project)
    })
  }

  return {
    mount,
    mountLoading: loading,
    onDoneMount: onDoneStub,
  }
}
