















import _ from 'lodash'

import { computed, defineComponent, onMounted } from '@vue/composition-api'

import { useAppBar } from '@/composables'
import { useCredit } from '@/composables/entities/products/use-credit'
import { useBuyProduct } from '@/composables/entities/products/use-buy-product'
import { trackPage } from '@/utils/analytics'

export default defineComponent({
  setup () {
    const { setAppBar } = useAppBar()
    setAppBar({ title: { label: 'Buy credits' } })
    onMounted(() => trackPage('Credit Listing'))

    const { product } = useCredit()
    const orderedPrices = computed(
      () => _.orderBy(product?.value?.prices, 'unitAmount'))
    const { buy } = useBuyProduct()

    return {
      orderedPrices,
      buy,
    }
  },
})
