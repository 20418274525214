import deepMerge from 'deepmerge'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

import { RootState } from '.'
import { AppBar } from '@/core/types/appbar'

const RESET_APP_BAR = 'Reset app bar'
const SET_APP_BAR = 'Set app bar'
const UPDATE_APP_BAR = 'Update app bar'

type AppBarState = {
  appBar: AppBar
}

const defaultAppBar: AppBar = {
  title: {
    label: '',
  },
}

const defaultState: AppBarState = { appBar: defaultAppBar }

const getters = <GetterTree<AppBarState, RootState>> {
  appBar: state => {
    return state.appBar
  },
}

const mutations = <MutationTree<AppBarState>> {
  [RESET_APP_BAR] (state) {
    state.appBar = defaultAppBar
  },
  [SET_APP_BAR] (state, newAppBar) {
    state.appBar = newAppBar
  },
  [UPDATE_APP_BAR] (state, newAppbar) {
    state.appBar = deepMerge(state.appBar, newAppbar)
  },
}

const actions = <ActionTree<AppBarState, RootState>> {
  resetAppBar ({ commit }) {
    commit(RESET_APP_BAR)
  },
  setAppBar ({ commit }, appBar: Partial<AppBar>) {
    commit(SET_APP_BAR, appBar)
  },
  updateAppBar ({ commit }, appBar: Partial<AppBar>) {
    commit(UPDATE_APP_BAR, appBar)
  },
}

export const appBarModule: Module<AppBarState, RootState> = {
  namespaced: false,
  state: defaultState,
  getters: getters,
  mutations: mutations,
  actions: actions,
}
