import { InjectionKey, provide, inject } from '@vue/composition-api'

import { Framework } from 'vuetify'

const VuetifySymbol: InjectionKey<string> = Symbol('vuetify')

export const provideVuetify = (vuetify: Framework): void => {
  provide<Framework>(VuetifySymbol, vuetify)
}

export const useVuetify = (): Framework => {
  const vuetify = inject<Framework>(VuetifySymbol)
  if (!vuetify) {
    throw new Error('Couldn\'t find router...')
  }
  return vuetify
}
