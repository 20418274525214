




import { defineComponent } from '@vue/composition-api'
import { usePaymentRedirect } from '@/composables/entities/billing'
import { trackCharge } from '@/utils/analytics/charge'
import { identifyUser } from '@/utils/analytics/user'
import { useAlert } from '@/composables/use-alert'
import { useCurrentUser } from '@/composables/entities/users/use-current-user'

export default defineComponent({
  setup () {
    const { showAlertSuccess } = useAlert()
    const { onBefore, onAfter } = usePaymentRedirect({ name: 'billing' })
    const { currentUser } = useCurrentUser()
    onBefore(session => {
      session && trackCharge(session)
      currentUser.value && identifyUser(currentUser.value)
    })
    onAfter(() => showAlertSuccess('Thank you for your purchase!'))
  },
})
