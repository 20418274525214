import gql from 'graphql-tag'

import {
  editableProjectFields,
  projectFields,
  projectFieldsNested,
} from '@/core/graphql/fragments'

export const PROJECT = gql`
  query getProject($id: String!) {
    project (id: $id) {
      ...projectFieldsNested
    }
  }
  ${projectFieldsNested}
`

export const ALL_MINIMAL_PROJECTS = gql`
  query allMinimalProjects($ownerId: String) {
    projects (ownerId: $ownerId) {
          id
          name
          createdAt
          updatedAt
          state
    }
  }
`

export const CREATE_PROJECT = gql`
  mutation createProject($name: String!,
                         $githubRepoUrl: String,
                         $ownerId: String,
                         $initScript:String,
                         $dependencies: JSONString,
                         $envVars: JSONString,
                         $hardware: Hardware)   {
    createProject (name: $name,
                   githubRepoUrl: $githubRepoUrl,
                   ownerId: $ownerId,
                   initScript: $initScript,
                   dependencies: $dependencies,
                   envVars: $envVars,
                   hardware: $hardware
    ) {
      project {
        ...projectFieldsNested
        owner {
          id
        }
      }
    }
  }
  ${projectFieldsNested}
`

export const UPDATE_PROJECT = gql`
  mutation updateProject ($id: String!,
                          $name: String,
                          $initScript: String,
                          $dependencies: JSONString,
                          $hardware: Hardware) {
    updateProject (id: $id,
                   name: $name,
                   initScript: $initScript,
                   dependencies: $dependencies,
                   hardware: $hardware
    ) {
      project {
        ...editableProjectFields
      }
    }
  }
  ${editableProjectFields}
`

export const DELETE_PROJECT = gql`
  mutation deleteProject($id: String!) {
    deleteProject (id: $id) {
      project {
        ...projectFields
      }
    }
  }
  ${projectFields}
`

export const MOUNT_PROJECT = gql`
  mutation mountProject($id: String!) {
    mountProject (id: $id) {
      project {
        id
        state
      }
    }
  }
`

export const UNMOUNT_PROJECT = gql`
  mutation umountProject($id: String!) {
    unmountProject (id: $id) {
      project {
        id
      }
    }
  }
`

export const FORCE_KILL_PROJECT = gql`
  mutation forceKillProject($id: String!) {
    forceKillProject (id: $id) {
      project {
        id
      }
    }
  }
`

export const HARD_REBOOT_PROJECT = gql`
  mutation hardRebootProject($id: String!) {
    hardRebootProject (id: $id) {
      project {
        id
      }
    }
  }
`

export const SUBSCRIBE_TO_PROJECT = gql`
  subscription projectSubscription ($id: String!) {
    projectSubscription (id: $id) {
      project {
        ...projectFieldsNested
      }
    }
  }
  ${projectFieldsNested}
`
