import _ from 'lodash'

import { Invoice } from '@/core/types/entities/billing/invoice'
import { analytics } from '@/utils/analytics/base'
import { getEventName, extractDaysSince } from '@/utils/analytics/common'
import { DOWNLOAD_ACTION } from '@/utils/analytics/actions'

const SUBJECT_INVOICE = 'Invoice'

export const trackInvoiceDownload = (invoice: Invoice) => {
  const props = _.omit(invoice,
    ['customerEmail', 'invoicePdf', '__typename'])
  analytics().track(
    getEventName(SUBJECT_INVOICE, DOWNLOAD_ACTION),
    // @ts-ignore
    { ...props, ...extractDaysSince(invoice.created, 'created') },
  )
}
