/// <reference types="@types/segment-analytics" />
import { SEGMENT_KEY } from '@/config'
import { analytics } from '@/utils/analytics/base'
import { AnalyticsProps } from '@/utils/analytics/common'

export const trackPage = (
  category?: string, name?: string, props?: AnalyticsProps) => {
  analytics().page(category, name, props)
}

export const loadAnalytics = () => {
  window.analytics.load(SEGMENT_KEY)
}
