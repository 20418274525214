import { useQuery, useResult } from '@vue/apollo-composable'

import { CHECKOUT_SESSION } from '@/core/graphql/billing/checkout-session'
import { computed } from '@vue/composition-api'
import { CheckoutSession } from '@/core/types/entities/billing/checkout-session'

export const useCheckoutSession = (sessionId: string) => {
  const {
    result,
    onResult,
    ...rest
  } = useQuery<{ checkoutSession: string}>(CHECKOUT_SESSION, { sessionId })
  const sessionString = useResult(result, '')
  const session = computed<CheckoutSession | undefined>(
    () => sessionString.value && JSON.parse(sessionString.value))

  const onResultStub = (fn: (session?: CheckoutSession) => void) => {
    return onResult(result => {
      if (result) {
        const session = JSON.parse(result?.data?.checkoutSession)
        fn(session)
      }
    })
  }
  return {
    checkoutSession: session,
    onResult: onResultStub,
    ...rest,
  }
}
