import { trackEntityEvent, trackMountOrUnmount } from '@/utils/analytics/common'
import {
  CREATE_ACTION,
  DELETE_ACTION,
  MOUNT_ACTION,
  UNMOUNT_ACTION,
} from '@/utils/analytics/actions'

export const trackProjectCreate = trackEntityEvent(CREATE_ACTION)
export const trackProjectDelete = trackEntityEvent(DELETE_ACTION)
export const trackProjectMount = trackMountOrUnmount(MOUNT_ACTION)
export const trackProjectUnmount = trackMountOrUnmount(UNMOUNT_ACTION)
