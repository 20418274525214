export type CustomErrorType = {
  message: string
  code?: string,
}

export enum ErrorType {
  BACKEND_GRAPHQL = 'backend graphql',
  BACKEND_REST = 'backend rest',
  NETWORK = 'network',
}
