import _ from 'lodash'

import { User } from '@/core/types/entities/user/user'
import { analytics } from '@/utils/analytics/base'
import { AnalyticsProps, getEventName } from '@/utils/analytics/common'
import { UPDATE_ACTION } from '@/utils/analytics/actions'

const SUBJECT_USER = 'User'
const SUBJECT_PROFILE = 'Profile'

const ACTION_CREATE_CHALLENGE = 'Created Challenge'
const ACTION_SIGNUP = 'Signed Up'
const ACTION_SIGNIN = 'Signed In'
const ACTION_SIGNOUT = 'Signed Out'

const extractUserProps = (user: User): AnalyticsProps => ({
  id: user.id,
  created: new Date(),
  email: user.email,
  name: `${user.firstName} ${user.lastName}`,
  has_enabled_github: user.hasEnabledGithub,
  has_setup_ssh_key: !_.isUndefined(user.sshPublicKey),
  plan: user.plan,
  cloud_credit: Number(user.cloudCredit?.toFixed(2)),
})

export const trackUserCreateChallenge = () => {
  const eventName = getEventName(SUBJECT_USER, ACTION_CREATE_CHALLENGE)
  analytics().track(eventName)
}

export const identifyUser = (user: User) => {
  analytics().identify(user.id, extractUserProps(user))
}

export const trackUserSignUp = (user: User, props?: AnalyticsProps) => {
  identifyUser(user)
  const eventName = getEventName(SUBJECT_USER, ACTION_SIGNUP)
  analytics().track(eventName, { ...extractUserProps(user), ...props })
}

export const trackUserSignIn = (user: User, props?: AnalyticsProps) => {
  identifyUser(user)
  const eventName = getEventName(SUBJECT_USER, ACTION_SIGNIN)
  analytics().track(eventName, { ...extractUserProps(user), ...props })
}

export const trackUserSignOut = (props?: AnalyticsProps) => {
  const eventName = getEventName(SUBJECT_USER, ACTION_SIGNOUT)
  analytics().track(eventName, props)
  analytics().reset()
}

export const trackProfileUpdate = (user: User, props?: AnalyticsProps) => {
  identifyUser(user)
  const eventName = getEventName(SUBJECT_PROFILE, UPDATE_ACTION)
  analytics().track(eventName, { ...extractUserProps, ...props })
}
