import { computed } from '@vue/composition-api'

import { useEventHook } from '@/composables/utils/use-event-hook'
import { LocalSettings } from '@/core/models/settings'
import { store } from '@/store'

export const useSettings = () => {
  const settings = computed(() => store.getters.settings as LocalSettings)

  const doneUpdateEvent = useEventHook()
  const update = async (newSettings: Partial<LocalSettings>) => {
    await store.dispatch('updateSettings', newSettings)
    doneUpdateEvent.trigger()
  }

  return {
    settings,
    update,
    onDone: doneUpdateEvent.on,
  }
}
