import { useMutation } from '@vue/apollo-composable'

import { UPDATE_DATASET } from '@/core/graphql/datasets-gql'

export const useUpdateDataset = () => {
  const { mutate, ...rest } = useMutation(UPDATE_DATASET)

  return {
    update: mutate,
    ...rest,
  }
}
