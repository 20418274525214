







import { defineComponent, watchEffect } from '@vue/composition-api'
import {
  provideRoute,
  provideRouter,
  provideStore,
  provideVuetify,
} from '@/composables'
import { useLayout } from '@/composables/use-layout'
import { useKeepAlive } from '@/composables/use-keep-alive'
import { useLocalStorage } from '@/composables/use-local-storage'

export default defineComponent({
  components: {
    'Modal': () => import('@/components/organisms/Modal.vue'),
  },
  setup (props, { root: { $router, $route, $store, $vuetify } }) {
    provideStore($store)
    provideRouter($router)
    provideRoute($route)
    provideVuetify($vuetify)

    watchEffect(
      () => { $vuetify.theme.dark = $store.getters.settings.darkModeEnabled })

    const { start: startKeepAlive } = useKeepAlive()
    startKeepAlive()

    const { listenLocalStorageChange } = useLocalStorage()
    listenLocalStorageChange()

    const { layout } = useLayout()

    return {
      layout,
    }
  },
})
