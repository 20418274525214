import _ from 'lodash'
import { Location } from 'vue-router'

import { useRouter } from '@/composables/use-router'
import { useRoute } from '@/composables'
import { useEventHook } from '@/composables/utils/use-event-hook'
import { useCheckoutSession }
  from '@/composables/entities/billing/use-checkout-session'
import { CheckoutSession } from '@/core/types/entities/billing/checkout-session'

export const usePaymentRedirect = (
  location: Location, timeout = 1000) => {
  const route = useRoute()
  const router = useRouter()
  const preRedirectEvent = useEventHook<CheckoutSession>()
  const postRedirectEvent = useEventHook<CheckoutSession>()
  const sessionId = _.isString(route.query.session_id)
    ? route.query.session_id
    : _.head(route.query.session_id)

  if (sessionId) {
    const { onResult } = useCheckoutSession(sessionId)
    onResult(checkoutSession => {
      preRedirectEvent.trigger(checkoutSession)
      setTimeout(() => {
        router.push(location)
        postRedirectEvent.trigger(checkoutSession)
      }, timeout)
    })
  }
  return {
    onBefore: preRedirectEvent.on,
    onAfter: postRedirectEvent.on,
  }
}
