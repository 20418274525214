import { useProduct } from '@/composables/entities/products/use-product'
import { STRIPE_PRODUCT_SUBSCRIPTION_ID } from '@/config'

export const useSubscription = () => {
  const { product: subscription, ...rest } = useProduct(
    STRIPE_PRODUCT_SUBSCRIPTION_ID)

  return {
    subscription,
    ...rest,
  }
}
