import { Ref, computed } from '@vue/composition-api'

import {
  Dataset as DatasetType,
  StorageType,
  DatasetKind,
  ParsedDataset,
  ParsedDatasetCloudStorage,
} from '@/core/types/entities/dataset/dataset'

import {
  CODE_SNIPPET_DBMS_URL,
  getCodeSnippet,
} from '@/core/models/code-snippet'

export const STORAGE_TYPE_S3 = 'S3'
export const STORAGE_TYPE_POSTGRES = 'POSTGRES'
const STORAGE_TYPE_ORACLE = 'ORACLE'

export const STORAGE_TYPES_CLOUD_STORAGE = [
  STORAGE_TYPE_S3,
]

export const STORAGE_TYPES_DATABASE = [
  STORAGE_TYPE_POSTGRES,
  STORAGE_TYPE_ORACLE,
]

export const STORAGE_TYPES = [
  ...STORAGE_TYPES_CLOUD_STORAGE,
  ...STORAGE_TYPES_DATABASE,
]

export const getCodeSnippetURLForDataset = (dataset: DatasetType) => {
  const storageType = dataset['storageType']
  if (STORAGE_TYPES_DATABASE.includes(storageType)) {
    return `${CODE_SNIPPET_DBMS_URL}/${storageType.toLowerCase()}.py`
  }
}

export const getCodeSnippetForDataset = async (dataset: DatasetType) => {
  const url = getCodeSnippetURLForDataset(dataset)
  if (url) return getCodeSnippet(url)
}

export const getConnectionParams =
  (dataset: DatasetType): ParsedDataset['parsedConnectionParams'] => {
    const params = JSON.parse(dataset['connectionParams'])
    if (params.hasOwnProperty('bucket_name')) {
      params.bucketName = params.bucket_name
      delete params.bucket_name
    }
    return params
  }

export const datasetKindFromStorageType =
  (storageType: StorageType): DatasetKind | void => {
    if (STORAGE_TYPES_CLOUD_STORAGE.includes(storageType)) {
      return 'Cloud Storage'
    } else if (STORAGE_TYPES_DATABASE.includes(storageType)) {
      return 'Database'
    } else if (storageType === 'NIBBLE') {
      return 'nibble'
    }
  }

export const getS3URL = (d: ParsedDatasetCloudStorage): string => {
  const params = d.parsedConnectionParams
  const bucketURL = `s3://${params.bucketName}`
  return params.prefix ? `${bucketURL}/${params.prefix}` : bucketURL
}

// TODO: allow non-ref
export const Dataset = (dataset: Ref<DatasetType | null>) => {
  return computed(() => {
    if (!dataset.value) return dataset.value
    return {
      ...dataset.value,
      parsedConnectionParams: getConnectionParams(dataset.value),
      kind: (dataset.value?.storageType
        ? datasetKindFromStorageType(dataset.value.storageType)
        : undefined
      ),
    }
  })
}
