




























import { defineComponent } from '@vue/composition-api'

import { useAppBar } from '@/composables'

export default defineComponent({
  setup () {
    const { setAppBar } = useAppBar()
    setAppBar({ title: { label: 'Settings' } })
  },
})
