import { GetterTree, MutationTree, ActionTree, Module } from 'vuex'

import { LocalSettings, defaultLocalSettings } from '@/core/models/settings'
import { RootState } from '.'

const UPDATE_SETTINGS = 'Update settings'

type State = {
  settings: LocalSettings
}

const defaultState: State = {
  settings: defaultLocalSettings,
}

const getters = <GetterTree<State, RootState>> {
  settings: state => {
    return state.settings
  },
}

const mutations = <MutationTree<State>> {
  [UPDATE_SETTINGS] (state, settingsPartial) {
    state.settings = { ...state.settings, ...settingsPartial }
  },
}

const actions = <ActionTree<State, RootState>> {
  initSettings ({ commit }) {
    const localStorageJSON = localStorage.getItem('settings-storage')
    if (localStorageJSON) {
      const localStorageSettings = JSON.parse(localStorageJSON)
      commit(UPDATE_SETTINGS, localStorageSettings)
    }
  },
  updateSettings ({ commit }, settingsPartial: Partial<LocalSettings>) {
    const currentLocalStorageSettings = JSON.parse(
      localStorage.getItem('settings-storage') || '{}')
    const newLocalStorageSettings = {
      ...currentLocalStorageSettings,
      ...settingsPartial,
    }
    localStorage.setItem(
      'settings-storage',
      JSON.stringify(newLocalStorageSettings),
    )
    commit(UPDATE_SETTINGS, settingsPartial)
  },
}

export const settingsModule: Module<State, RootState> = {
  namespaced: false,
  state: defaultState,
  getters: getters,
  mutations: mutations,
  actions: actions,
}
