import gql from 'graphql-tag'

const priceFields = gql`
  fragment priceFields on Price {
    id
    currency
    nickname
    type
    unitAmount
  }
`

const productFields = gql`
  fragment productFields on Product {
    id
    name
  }
`

export const PRODUCT = gql`
  query getProduct($id: String!) {
    product (id: $id) {
      ...productFields
      prices {
        ...priceFields
      }
    }
  }
  ${productFields}
  ${priceFields}
`

export const ALL_PRODUCTS = gql`
  query allProducts {
    products {
      ...productFields
    }
  }
  ${productFields}
`

export const CREATE_CHECKOUT_SESSION = gql`
  mutation createStripeCheckoutSession ($priceIds: [String]) {
    createStripeCheckoutSession (priceIds: $priceIds) {
      sessionId
    }
  }
`
