




















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: {
    'NavigationDrawer':
      () => import('@/components/organisms/NavigationDrawer.vue'),
    'AppBar': () => import('@/components/organisms/AppBar.vue'),
    'Alert': () => import('@/components/organisms/Alert.vue'),
    'Snackbar': () => import('@/components/snackbar/Snackbar.vue'),
  },
})
