

















































import * as _ from 'lodash'
import {
  computed,
  defineComponent,
  ref,
  watch,
  watchEffect,
} from '@vue/composition-api'

import {
  Dataset as DatasetType,
  ParsedDatasetCloudStorage,
} from '@/core/types/entities/dataset/dataset'
import { useRouter } from '@/composables'
import { useAppBar } from '@/composables/use-appbar'
import { useDataset } from '@/composables/entities/datasets/use-dataset'
import { useAlert } from '@/composables/use-alert'
import { useValidateDelete } from '@/composables/use-modals/use-validate-delete'
import {
  Dataset,
  getS3URL,
  getCodeSnippetForDataset,
} from '@/core/models/dataset'

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    'SkeletonLoader': () => import('@/components/molecules/SkeletonLoader.vue'),
    'DebugInfos': () => import('@/components/organisms/DebugInfos.vue'),
  },
  setup (props) {
    const router = useRouter()
    const { showAlert } = useAlert()

    const datasetId = props.id

    const {
      dataset: rawDataset,
      loading,
      update: updateDataset,
      deleteDataset,
      onDoneDelete,
    } = useDataset(datasetId)

    const dataset = Dataset(rawDataset)

    const { updateAppBar } = useAppBar()
    updateAppBar({ title: {
      editAction: async (name: DatasetType['name']) => {
        await updateDataset(name)
        showAlert({ type: 'success', message: 'Update successful' })
      },
    } })
    watch(
      () => dataset?.value?.name,
      (name) => updateAppBar({ title: { label: name } }),
      { immediate: true },
    )

    // eslint-disable-next-line
    const properties = computed(() => {
      if (dataset.value) {
        const defaults = _.pick(dataset.value, ['kind', 'storageType'])
        switch (dataset.value.kind) {
          case 'Cloud Storage':
            return {
              ...defaults,
              url: getS3URL(
                dataset.value as ParsedDatasetCloudStorage),
            }
          case 'Database':
            return {
              ...defaults,
              ...dataset.value.parsedConnectionParams,
            }
          case 'nibble':
            return {
              ..._.pick(dataset.value, ['kind']),
            }
        }
      }
    })

    const codeSnippet = ref<string>()
    watchEffect(async () => {
      if (dataset.value) {
        const res = await getCodeSnippetForDataset(dataset.value)
        codeSnippet.value = res?.data
      }
    })

    const { validateDelete } = useValidateDelete()
    const handleDelete = () => validateDelete(
      dataset.value?.name, deleteDataset)

    onDoneDelete(() => router.push({ name: 'Dataset.index' }))

    return {
      Dataset,
      loading,
      dataset,
      handleDelete,
      properties,
      codeSnippet,
    }
  },
})
