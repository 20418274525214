import { useMutation } from '@vue/apollo-composable'
import { UPDATE_PROJECT } from '@/core/graphql/projects-gql'
import { UpdateProjectDto }
  from '@/core/types/entities/project/project/dto/update-project.dto'

export const useUpdateProject = () => {
  const { mutate, ...rest } = useMutation<any, UpdateProjectDto>(UPDATE_PROJECT)
  return {
    update: mutate,
    ...rest,
  }
}
