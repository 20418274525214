




import { defineComponent, onMounted } from '@vue/composition-api'
import { useRoute, useRouter } from '@/composables'
import { useLoginUser } from '@/composables/entities/users/use-signin'
import { trackUserSignIn } from '@/utils/analytics/user'

export default defineComponent({
  setup () {
    const {
      loginUser,
      onDone: onDoneLogin,
    } = useLoginUser()

    const route = useRoute()

    const router = useRouter()

    onMounted(() => {
      const credentials = route.query.credentials
      if (credentials && typeof credentials === 'string') {
        loginUser(decodeURI(credentials))
      } else {
        router.push({ name: 'login' })
      }
    })

    onDoneLogin((user) => {
      if (user) trackUserSignIn(user)
      router.push({ name: 'home' })
    })
  },
})
