import { Entity } from '@/core/types/entities/entity'
import { Dataset } from '@/core/types/entities/dataset/dataset'
import { ProjectService }
  from '@/core/types/entities/project/project-service/project-service'

export enum ProjectState {
  UNMOUNTED = 'UNMOUNTED',
  MOUNTING = 'MOUNTING',
  MOUNTED = 'MOUNTED',
  UNMOUNTING = 'UNMOUNTING',
}

export enum ComputeType {
  CPU = 'CPU',
  GPU = 'GPU',
}

export type FileTreeItemWithoutPath = {
  name: string,
  type: string,
  children?: FileTreeItemWithoutPath[]
}

// TODO: generic this?
export type FileTreeItem = {
  name: string,
  type: string,
  path: string,
  children?: FileTreeItem[]
}

export type Hardware = (
  'T3_SM' |
  'M5_XL' |
  'M5_4X' |
  'G3S_XL' |
  'P2_XL' |
  'P3_2X'
)

export type HardwareChoice = (
  'T3_SMALL' |
  'M5_XLARGE' |
  'M5_4XLARGE' |
  'P2_XLARGE' |
  'P3_2XLARGE' |
  'G3S_XLARGE'
)

export type EnvVar = {
  key: string,
  value: string
}

export type Dependency = {
  name: string,
  version?: string
}

export type Project = Entity & {
  __typename: 'Project',
  mountedDatasets: Dataset[],
  name: string,
  state: ProjectState.UNMOUNTED
  | ProjectState.MOUNTING
  | ProjectState.MOUNTED
  | ProjectState.UNMOUNTING
  computeType: ComputeType.CPU | ComputeType.GPU,
  hardware: HardwareChoice,
  canonicalName: string,
  filetree?: FileTreeItem[],
  secretToken: string,
  hostname: string,
  sshUrl: string,
  hasSnapshot: boolean,
  initScript: string,
  dependencies: Dependency[],
  envVars: EnvVar[]
  attachedServices: ProjectService[]
}

export type ProjectRaw =
  Omit<Project,
  'filetree' | 'dependencies' | 'envVars' | 'attachedServices'> & {
    filetree: string
    dependencies: string
    envVars: string
    attachedServices: (
      Omit<ProjectService, 'customCmdArgs'> &
      { customCmdArgs: string }
    )[]
  }

type MinimalProjectKeys = 'id' | 'name' | 'createdAt' | 'updatedAt' | 'state'
export type MinimalProject = Pick<Project, MinimalProjectKeys >
