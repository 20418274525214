import { ref } from '@vue/composition-api'
import { User } from '@/core/types/entities/user/user'
import { Cookies, COOKIE_TOKEN, COOKIE_TOKEN_DURATION } from '@/utils/cookies'
import { useEventHook } from '@/composables/utils/use-event-hook'
import {
  LoginResponse,
  loginWithCredentials,
  loginUser as login,
} from '@/core/services/user-service'
import { setLocalStorage } from '@/core/services/local-storage'
import { UserLocalStorage } from '@/core/types/user-local-storage'
import { useSnackbar } from '@/composables/use-snackbar'
import { CustomError } from '@/core/models/custom-error'
import { ErrorType } from '@/core/types/entities/error/custom-error'
import { AxiosResponse } from 'axios'

type LoginUserProps = {
  email: string
  loginCode: string
} | string

export const useLoginUser = () => {
  const doneUpdateEvent = useEventHook<User>()

  const loading = ref(false)

  const { showError } = useSnackbar()

  const loginUser = async (props: LoginUserProps) => {
    loading.value = true
    try {
      const response = await handleLogin(props)
      handleSuccess(response.data)
      return response.data.user
    } catch (e) {
      handleError(e)
    }
    loading.value = false
  }

  const isSignInWithCredentials = (props: LoginUserProps): props is string => (
    typeof props === 'string'
  )

  const handleLogin =
    (props: LoginUserProps): Promise<AxiosResponse<LoginResponse>> => {
      if (isSignInWithCredentials(props)) {
        return loginWithCredentials(props)
      } else {
        return login(props.email, props.loginCode)
      }
    }

  const handleSuccess = (response: LoginResponse) => {
    Cookies.set(COOKIE_TOKEN, response.token, COOKIE_TOKEN_DURATION)
    setLocalStorage<UserLocalStorage>('user', { loggedIn: true })
    doneUpdateEvent.trigger(response.user)
  }

  const handleError = (error: any) => {
    const customError = CustomError({
      message: error.response.data.message,
      code: error.response.data.code,
      type: ErrorType.BACKEND_REST,
    })
    showError({ message: customError.message })
    loading.value = false
  }

  return {
    loginUser,
    loading,
    onDone: doneUpdateEvent.on,
  }
}
