import axios from 'axios'

const CODE_SNIPPET_BASE_URL = (
  'https://raw.githubusercontent.com/nibbleai/code-snippets/master')
const CODE_SNIPPET_CONNECTORS_URL = `${CODE_SNIPPET_BASE_URL}/connectors`
export const CODE_SNIPPET_DBMS_URL = `${CODE_SNIPPET_CONNECTORS_URL}/dbms`

export const getCodeSnippet = async (url: string) => {
  return axios.get(url)
}
