
















































import _ from 'lodash'
import { computed, defineComponent } from '@vue/composition-api'
import { EntityName } from '@/core/types/enums'
import { DEFAULT_MAX_ITEMS_PER_PAGE, headerLayouts } from '@/core/utils/table'
import { useIndexViewAppbar } from '@/composables/use-index-view-appbar'
import { useShowEntity } from '@/composables/use-router/use-show-entity'
import { useProjects }
  from '@/composables/entities/projects/project/use-projects'
import {
  isProjectMounted,
  isProjectMounting,
  isProjectUnmounted,
  isProjectUnmounting,
} from '@/core/models/project/project'
import {
  trackProjectMount,
  trackProjectUnmount,
} from '@/utils/analytics/project'
import { useMountProject }
  from '@/composables/entities/projects/project/use-mount-project'
import { useUnmountProject }
  from '@/composables/entities/projects/project/use-unmount-project'

export default defineComponent({
  components: {
    'DataTable': () => import('@/components/molecules/DataTable.vue'),
  },
  setup () {
    const { projects, loading } = useProjects()

    const ACTION_HEADER = { text: 'Actions', sortable: false, value: 'actions' }

    const headers = computed(
      () => [...headerLayouts[EntityName.PROJECT], ACTION_HEADER],
    )

    useIndexViewAppbar(EntityName.PROJECT)

    const {
      mount: mountProject,
      onDone: onDoneMountProject,
    } = useMountProject()

    onDoneMountProject((project) => {
      trackProjectMount(project!)
    })

    const {
      unmount: unmountProject,
      onDone: onDoneUnmountProject,
    } = useUnmountProject()

    onDoneUnmountProject((project) => {
      trackProjectUnmount(project!)
    })
    useIndexViewAppbar(EntityName.PROJECT)
    const isProjectTableFooterHidden = computed(() =>
      projects.value.length <= DEFAULT_MAX_ITEMS_PER_PAGE,
    )
    return {
      projects: computed(() => _.orderBy(projects.value, 'updatedAt', 'desc')),
      loading,
      headers,
      showEntity: useShowEntity(),
      isProjectMounted,
      isProjectMounting,
      isProjectUnmounted,
      isProjectUnmounting,
      mountProject,
      unmountProject,
      DEFAULT_MAX_ITEMS_PER_PAGE,
      isProjectTableFooterHidden,
    }
  },
})
