import { Invoice } from '@/core/types/entities/billing/invoice'
import { EntityName } from '@/core/types/enums'
import { DomainSpecificEntity } from '@/core/types/entities/entity'
import { HeaderTableLayoutMapper, HeaderType } from '@/core/types/layout'
import { Project } from '@/core/types/entities/project/project/project'
import { Dataset } from '@/core/types/entities/dataset/dataset'

const createdHeader: HeaderType<DomainSpecificEntity> = {
  text: 'Created',
  sortable: true,
  value: 'createdAt',
}

const updatedHeader: HeaderType<DomainSpecificEntity> = {
  text: 'Last modified',
  sortable: true,
  value: 'updatedAt',
}

const projectHeader: HeaderTableLayoutMapper<Project> = [
  {
    text: 'Name',
    sortable: true,
    value: 'name',
  },
  {
    text: 'State',
    sortable: true,
    value: 'state',
  },
  updatedHeader,
  createdHeader,
]

const datasetHeader: HeaderTableLayoutMapper<Dataset> = [
  {
    text: 'Name',
    sortable: true,
    value: 'name',
  },
  {
    text: 'Storage',
    sortable: true,
    value: 'storageType',
  },
  updatedHeader,
  createdHeader,
]

const invoiceHeader: HeaderTableLayoutMapper<Invoice> = [
  {
    text: 'Reference',
    value: 'number',
  },
  {
    text: 'Date',
    value: 'created',
  },
  {
    text: 'Amount Due',
    align: 'center',
    value: 'amountDue',
  },
  {
    text: 'Amount Paid',
    align: 'center',
    value: 'amountPaid',
  },
  {
    text: 'Paid',
    align: 'center',
    value: 'paid',
  },
  {
    text: 'Download',
    align: 'center',
    value: 'invoicePdf',
  },
]

const headerLayouts = {
  [EntityName.PROJECT]: projectHeader,
  [EntityName.DATASET]: datasetHeader,
  Invoice: invoiceHeader,
}

const DEFAULT_MAX_ITEMS_PER_PAGE = 15

export {
  headerLayouts,
  DEFAULT_MAX_ITEMS_PER_PAGE,
}
