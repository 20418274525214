import { computed } from '@vue/composition-api'

import { useStore } from '@/composables'
import {
  showAlert,
  resetAlert,
  updateAlert,
  showAlertSuccess,
  showAlertError,
  showAlertWarning,
} from '@/core/models/alert'

export const useAlert = () => {
  const store = useStore()
  const alert = computed(() => store.getters.alert)

  return {
    alert,
    showAlert,
    resetAlert,
    updateAlert,
    showAlertSuccess,
    showAlertError,
    showAlertWarning,
  }
}
