


















import _ from 'lodash'
import { computed, defineComponent } from '@vue/composition-api'

import { EntityName } from '@/core/types/enums'
import { DEFAULT_MAX_ITEMS_PER_PAGE, headerLayouts } from '@/core/utils/table'
import { useIndexViewAppbar } from '@/composables/use-index-view-appbar'
import { useDatasets } from '@/composables/entities/datasets/use-datasets'
import { useShowEntity } from '@/composables/use-router/use-show-entity'

export default defineComponent({
  components: {
    'DataTable': () => import('@/components/molecules/DataTable.vue'),
  },
  setup () {
    const { datasets, loading } = useDatasets()
    const headers = headerLayouts[EntityName.DATASET]
    useIndexViewAppbar(EntityName.DATASET)
    const isDatasetTableFooterHidden = computed(() =>
      datasets.value.length <= DEFAULT_MAX_ITEMS_PER_PAGE,
    )

    return {
      datasets: computed(() => _.orderBy(datasets.value, 'updatedAt', 'desc')),
      loading,
      headers,
      showEntity: useShowEntity(),
      DEFAULT_MAX_ITEMS_PER_PAGE,
      isDatasetTableFooterHidden,
    }
  },
})
